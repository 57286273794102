import {
  ReactEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown as faSelectDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  ClickAwayListener,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import DropdownOption from 'components/SteelUI/molecules/dropdowns/components/DropdownOption';
import GroupDropdownOption from 'components/SteelUI/molecules/dropdowns/components/GroupDropdownOption';
import { Body } from 'components/SteelUI/atoms/typography';
import SupportingText from 'components/SteelUI/atoms/SupportingText';

const useStyles = makeStyles<{ disabled: boolean }>()(
  ({ palette }, { disabled }) => ({
    container: {
      width: '100%',
      '& .MuiOutlinedInput-root': {
        '&.Mui-disabled': {
          color: '#A7ACB2',
          backgroundColor: '#EDEEF0',
          borderColor: '#F6F7F7',
          '&.MuiInputLabel-root': {
            color: '#A7ACB2',
          },
        },
      },
      '& .MuiOutlinedInput-root:not(.Mui-disabled)': {
        '&:hover': {
          fieldset: {
            borderColor: palette.primary.main,
            borderWidth: 2,
          },
        },
      },
    },
    label: {
      color: disabled ? '#A7ACB2' : '#212224',
      '&:not(.MuiInputLabel-shrink)': {
        top: -6,
      },
    },
    focused: {
      color: palette.primary.main,
    },
    searchBoxWrapper: {
      width: '100%',
      borderRadius: '0.5rem 0.5rem 0 0',
      padding: 0,
      '&:focus': {
        backgroundColor: '#FFF !important',
        border: '3px solid #21519933',
        height: '2.75rem',
      },
      '&:hover': {
        backgroundColor: '#FFF !important',
      },
    },
    searchBox: {
      width: '100%',
      borderRadius: '0.5rem 0.5rem 0 0',
      '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
        borderRadius: '0.5rem 0.5rem 0 0',
        border: '1px solid #EDEEF0',
      },
      '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${palette.primary.main}`,
      },
      '& .Mui-focused': {
        backgroundColor: '#FFF',
      },
    },
    searchIcon: {
      color: '#64676B',
      height: '1rem',
      width: '1rem',
    },
    input: {
      color: '#64676B',
      height: '2.75rem',
      padding: 0,
      '&::placeholder': {
        opacity: 1,
      },
      // Hides the native clear button in Chrome, Edge, Safari
      '&::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration':
        {
          display: 'none',
        },
      // For Firefox
      '&::-moz-clear': {
        display: 'none',
      },
    },
    select: {
      borderRadius: '0.5rem',
      maxHeight: '2.75rem',
      color: '#212224',
      '&.MuiInputBase-root:not(.Mui-disabled)': {
        '&.Mui-error fieldset': {
          borderColor: palette.error.main,
          borderWidth: 2,
        },
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline:not(.Mui-disabled)': {
        '& fieldset': {
          borderColor: `${palette.primary.main} !important`,
          borderWidth: 2,
        },
        '&.Mui-error fieldset': {
          borderColor: palette.error.main,
          borderWidth: 2,
        },
      },
      '&.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline:not(.Mui-disabled)':
        {
          '& fieldset': {
            borderColor: palette.primary.main,
            borderWidth: 2,
          },
          '&.Mui-error fieldset': {
            borderColor: palette.error.main,
            borderWidth: 2,
          },
        },
      '& fieldset': {
        '&.MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${palette.grey[100]}`,
        },
      },
    },
    selected: {
      borderRadius: '0.5rem',
      maxHeight: '2.75rem',
      color: '#212224',
    },
    selectIcon: {
      color: '#64676B',
      height: '0.5rem',
      width: '0.5rem',
      right: '1rem',
      top: '1.125rem',
    },
    menuPaper: {
      maxHeight: '30rem',
      maxWidth: '18.5rem',
      borderRadius: '0.5rem !important',
      boxShadow: 'none',
      filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))',
    },
    menuList: {
      paddingTop: 0,
    },
    menuItem: {
      height: '2.625rem',
      minHeight: '2.625rem',
      color: '#64676B',
      '&.Mui-selected': {
        backgroundColor: '#FFF',
      },
      '&:focus': {
        backgroundColor: '#FFF !important',
        border: '3px solid #21519933',
      },
      '&:hover': {
        backgroundColor: `${palette.secondary.main} !important`,
      },
    },
    menuItemSelected: {
      height: '2.625rem',
      minHeight: '2.625rem',
      backgroundColor: `${palette.secondary.main} !important`,
      fontWeight: 700,
      color: '#212224',
    },
    hiddenMenuItem: {
      display: 'none',
    },
    box: {
      '&:hover': {
        '& .MuiInputLabel-formControl': {
          color: palette.primary.main,
        },
      },
      '&:focus': {
        '& .MuiInputLabel-formControl': {
          color: palette.primary.main,
        },
      },
    },
  })
);

export type SearchDropdownOption = {
  label: string;
  value: string;
  group?: string;
  groupLabel?: string;
  icon?: JSX.Element | null;
};

export type Props = {
  options: SearchDropdownOption[];
  groups?: Record<string, SearchDropdownOption[]>;
  value: string[] | string;
  label?: string;
  multipleRenderValueLabel?: string;
  placeholder?: string;
  required?: boolean;
  error?: string;
  groupSelectable?: boolean;
  onChange: <T>(evt: T) => void;
  searchable?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  radio?: boolean;
  id?: string;
};

const Dropdown = ({
  options,
  groups,
  value,
  label: providedLabel,
  multipleRenderValueLabel,
  required = false,
  error = '',
  groupSelectable = false,
  onChange,
  searchable = false,
  multiple = false,
  disabled = false,
  radio = false,
  id,
}: Props) => {
  const [visibleOptions, setVisibleOptions] =
    useState<SearchDropdownOption[]>(options);
  const [showTooltip, setShowTooltip] = useState(false);
  const [selectIsOpen, setSelectIsOpen] = useState(false);

  const { classes, cx } = useStyles({ disabled });
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const ref = useRef(null);
  const firstOptionRef = useRef<HTMLLIElement>(null);

  const handleSelectChange: ReactEventHandler<
    SelectChangeEvent<string | string[]> | HTMLLIElement
  > = (event) => {
    setSearchValue(null);
    onChange(event);
  };

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchValue(event.target.value);
    const newOptions = options.filter((option) =>
      option.label.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setVisibleOptions(newOptions);
  };

  const handleClearSearch = () => {
    setSearchValue(null);
    setVisibleOptions(options);
  };

  const label = required ? `* ${providedLabel}` : providedLabel;
  const renderValue = () => {
    // if value is an array with more than one element, display the label with the number of selected items
    // if the value an array with only one element, display the option label corresponding to that value
    // if the value is a string, display the option label corresponding to that value

    if (Array.isArray(value) && value.length > 1) {
      const text = multipleRenderValueLabel ?? label;
      return `${text} (${value.length})`;
    }

    if (Array.isArray(value) && value.length === 1) {
      const option = options.find((option) => option.value === value[0]);
      return option ? option.label : label;
    }

    const option = options.find((option) => option.value === value);

    if (option?.icon) {
      return (
        <Stack direction="row">
          {option.icon}
          {option.label}
        </Stack>
      );
    }
    return option ? option.label : label;
  };

  const isGroupSelected = (group: string) => {
    if (!groups) {
      return false;
    }
    const groupOptions = groups[group];
    return groupOptions.every((option) => value.includes(option.value));
  };

  const groupCheckboxState = (group: string) => {
    if (!groups) {
      return 'unchecked';
    }
    const groupOptions = groups[group];
    if (groupOptions.every((option) => value.includes(option.value))) {
      return 'checked';
    }
    if (groupOptions.some((option) => value.includes(option.value))) {
      return 'indeterminate';
    }
    return 'unchecked';
  };

  const handleSelectGroup = (group: string) => {
    if (!groups) {
      return;
    }
    const groupOptions = groups[group];
    const groupValues = groupOptions.map((option) => option.value);

    let newValue: string[] = [];

    if (groupValues.every((val) => !value.includes(val))) {
      newValue = [...value, ...groupValues];
    }

    if (groupValues.some((val) => value.includes(val))) {
      newValue = [
        ...value,
        ...groupValues.filter((val) => !value.includes(val)),
      ];
    }

    if (groupValues.every((val) => value.includes(val))) {
      newValue = Array.isArray(value)
        ? value.filter((val) => !groupValues.includes(val))
        : [];
    }
    handleSelectChange({ target: { value: newValue } });
  };

  const countSelectedInGroup = (group: string) => {
    if (!groups) {
      return 0;
    }
    const groupOptions = groups[group];
    return groupOptions.filter((option) => value.includes(option.value)).length;
  };

  const getTooltipTitle = () => {
    if (Array.isArray(value) && value.length) {
      return value
        .map((val) => options.find((option) => option.value === val)?.label)
        .join(', ');
    }
    return '';
  };

  const handleSelectOpen = () => {
    setSelectIsOpen(true);
    setShowTooltip(false);
  };

  const handleSelectClose = () => {
    setSelectIsOpen(false);
  };

  const handleSelectMouseEnter = () => {
    if (!selectIsOpen) {
      setShowTooltip(true);
    }
  };

  const handleSelectMouseLeave = () => {
    setShowTooltip(false);
  };

  useEffect(() => {
    setVisibleOptions(options);
  }, [options]);

  return (
    <Tooltip open={showTooltip} title={getTooltipTitle()} placement="top" arrow>
      <Box display="flex" flexDirection="column" className={classes.box}>
        <FormControl className={classes.container}>
          <InputLabel
            error={!!error}
            classes={{
              root: classes.label,
              focused: classes.focused,
            }}
          >
            {label}
          </InputLabel>
          <Select
            disabled={disabled}
            multiple={multiple}
            IconComponent={(props: { className?: string }) => (
              <FontAwesomeIcon
                icon={faSelectDown}
                className={cx(props.className, classes.selectIcon)}
              />
            )}
            data-testid={`atomic-dropdown-${id ?? label}`}
            className={value.length > 0 ? classes.selected : classes.select}
            value={multiple && !value ? [] : value}
            label={label}
            error={!!error}
            onChange={handleSelectChange}
            onOpen={handleSelectOpen}
            onClose={handleSelectClose}
            MenuProps={{
              classes: { paper: classes.menuPaper, list: classes.menuList },
              disableAutoFocusItem: true,
              anchorEl: ref.current,
              autoFocus: false,
              disableScrollLock: true,
            }}
            ref={ref}
            renderValue={renderValue}
            onMouseEnter={handleSelectMouseEnter}
            onMouseLeave={handleSelectMouseLeave}
          >
            {!searchable ? null : (
              <ClickAwayListener
                onClickAway={() => {
                  if (!handleClearSearch) {
                    return;
                  }
                  setSearchValue(null);
                  handleClearSearch();
                }}
              >
                <MenuItem className={classes.searchBoxWrapper}>
                  <TextField
                    type="search"
                    placeholder="Search"
                    className={classes.searchBox}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon
                            className={classes.searchIcon}
                            icon={faMagnifyingGlass}
                          />
                        </InputAdornment>
                      ),
                      classes: { input: classes.input },
                    }}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                      if (e.key === 'ArrowDown') {
                        firstOptionRef?.current?.focus();
                      }
                    }}
                    onChange={(e) => handleSearchChange(e)}
                    value={searchValue ?? ''}
                  />
                </MenuItem>
              </ClickAwayListener>
            )}
            {!groups
              ? visibleOptions?.map(({ value: optionValue, label, icon }) => {
                  const isSelected = Array.isArray(value)
                    ? value.indexOf(optionValue) > -1
                    : value === optionValue;

                  return (
                    <DropdownOption
                      data-testid={`atomic-dropdown-option-${optionValue}`}
                      key={optionValue}
                      value={optionValue}
                      label={label}
                      icon={icon}
                      isSelected={isSelected}
                      onSelect={handleSelectChange}
                      onClick={() => {
                        if (radio && isSelected && !required) {
                          // Deselect the radio option
                          handleSelectChange({ target: { value: '' } });
                        }
                      }}
                      variant={
                        multiple ? 'checkbox' : radio ? 'radio' : 'default'
                      }
                    />
                  );
                })
              : Object.entries(groups).map(([key, options]) => {
                  const optionsFormatted: ReactNode[] = [];
                  const groupVisibleOptions = (
                    options as SearchDropdownOption[]
                  ).filter((option) =>
                    visibleOptions.some(({ value }) => value === option.value)
                  );
                  // Don't show group label if there are no visible options for it
                  const group =
                    !groupVisibleOptions.length ? null : !groupSelectable ? (
                      <Box sx={{ padding: '6px 16px' }}>
                        <Body weight="bold">
                          {options[0]?.groupLabel ?? key}
                        </Body>
                      </Box>
                    ) : (
                      <GroupDropdownOption
                        key={key}
                        value={key}
                        label={options[0]?.groupLabel ?? key}
                        isSelected={isGroupSelected(key)}
                        indeterminate={
                          groupCheckboxState(key) === 'indeterminate'
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          e.bubbles = false;
                          e.stopPropagation();
                          handleSelectGroup(key);
                        }}
                        onSelect={(e) => {
                          e.preventDefault();
                          e.bubbles = false;
                          e.stopPropagation();
                          handleSelectGroup(key);
                        }}
                        selectedCount={countSelectedInGroup(key)}
                        checkbox={multiple}
                      />
                    );
                  optionsFormatted.push(group);
                  optionsFormatted.push(
                    groupVisibleOptions.map(
                      ({ value: optionValue, label, icon }) => (
                        <DropdownOption
                          key={optionValue}
                          value={optionValue}
                          label={label}
                          icon={icon}
                          isSelected={
                            Array.isArray(value)
                              ? value.indexOf(optionValue) > -1
                              : value === optionValue
                          }
                          variant={
                            multiple ? 'checkbox' : radio ? 'radio' : 'default'
                          }
                          indented
                        />
                      )
                    )
                  );
                  return optionsFormatted;
                })}
          </Select>
        </FormControl>
        <Box sx={{ paddingTop: !error ? 0 : '8px' }}>
          <SupportingText error={error} />
        </Box>
      </Box>
    </Tooltip>
  );
};
export default Dropdown;
