import { makeStyles } from '@mui/styles';
import FormSection from './FormSection';
import { DatePicker } from 'components/SteelUI/molecules';
import { useField } from 'react-final-form';
import RadioGroup, {
  RadioGroupProps,
} from 'components/SteelUI/molecules/RadioGroup';
import { stringValidator } from 'components/library/form/fieldValidators';

const useStyles = makeStyles({
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    columnGap: '24px',
    rowGap: '40px',
  },
});

export const PickUpDateInput = () => {
  const { input } = useField('pickupDate');
  return (
    <DatePicker
      onChange={input.onChange}
      value={input.value || null}
      label="Pickup Date"
      required={false}
    />
  );
};

const DateRadioInput = (
  props: { name: string } & Omit<RadioGroupProps, 'value' | 'onChange'>
) => {
  const { input } = useField(props.name, {
    validate: stringValidator,
    type: 'radio',
  });

  return (
    <RadioGroup value={input.value} onChange={input.onChange} {...props} />
  );
};

const DateSelect = () => {
  const classes = useStyles();

  return (
    <FormSection title="Date">
      <div className={classes.content}>
        <DateRadioInput
          name="shipsIn30Days"
          label="Will it ship in the next 30 days?"
          options={[
            {
              value: 'true',
              label: 'Yes',
            },
            {
              value: 'false',
              label: 'No',
            },
          ]}
          required={true}
        />

        <DateRadioInput
          name="expeditedUrgency"
          label="Do you need it expedited?"
          options={[
            {
              value: 'true',
              label: 'Yes',
            },
            {
              value: 'false',
              label: 'No',
            },
          ]}
          required={true}
        />

        <PickUpDateInput />
      </div>
    </FormSection>
  );
};

export default DateSelect;
