import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Skeleton, Stack } from '@mui/material';

import { Body, H2 } from 'components/SteelUI/atoms/typography';
import {
  HistoricalRate,
  RateSource,
  RateType,
} from 'features/LogisticsQuoteManager/hooks/useGetHistoricalRates';

import Chart, { ChartData } from './Chart';
import LocationDetails from './LocationDetails';
import { useFlags } from 'launchdarkly-react-client-sdk';

const DATASET_CONFIG = {
  REIBUS: {
    color: '#2D6CCB',
    name: 'Reibus Rate',
    id: 'reibus',
    source: 'REIBUS' as RateSource,
  },
  DAT_SPOT: {
    color: '#F98522',
    name: 'DAT - Broker to Carrier Spot',
    id: 'datB2C',
    source: 'DAT' as RateSource,
    rateType: 'SPOT' as RateType,
  },
  DAT_CONTRACT: {
    color: '#F34E7C',
    name: 'DAT - Carrier to Contract',
    id: 'datC2C',
    source: 'DAT' as RateSource,
    rateType: 'CONTRACT' as RateType,
  },
  GREENSCREENS: {
    color: '#24D8A5',
    name: 'Greenscreens',
    id: 'greenscreens',
    source: 'GREENSCREENS' as RateSource,
    rateType: 'SPOT' as RateType,
  },
};

type LocationDetails = {
  city?: string;
  state?: string;
  loadToTruckRatio?: number;
  mciScore?: number;
};

type Props = {
  isLoadingHistoricalRates: boolean;
  hasNeverFetched: boolean;
  historicalRates: HistoricalRate[];
  origin: LocationDetails;
  destination: LocationDetails;
  equipmentType?: number;
  isLoadingMarketConditions: boolean;
};

const RateChart = ({
  isLoadingHistoricalRates,
  hasNeverFetched,
  historicalRates,
  origin,
  destination,
  equipmentType,
  isLoadingMarketConditions,
}: Props) => {
  const chartIcon = icon({ name: 'chart-line', style: 'light' });
  const warningIcon = icon({ name: 'exclamation-triangle', style: 'solid' });

  if (hasNeverFetched) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        direction="column"
        height="100%"
        spacing="48px"
      >
        <H2 textAlign="center">Rate History Will Appear Here</H2>
        <H2>
          <FontAwesomeIcon color="#CACDD1" icon={chartIcon} size="6x" />
        </H2>
      </Stack>
    );
  }

  if (isLoadingHistoricalRates) {
    return (
      <Skeleton
        variant="rounded"
        sx={{
          width: '100%',
          height: '100%',
        }}
      />
    );
  }

  if (!historicalRates?.length) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        direction="row"
        height="100%"
        spacing="24px"
      >
        <Body size="large">
          <FontAwesomeIcon color="#FFA500" icon={warningIcon} />
        </Body>
        <Body size="large" weight="semibold">
          No Rate Data Available For This Lane
        </Body>
      </Stack>
    );
  }

  const reibusDataset: ChartData = {
    ...DATASET_CONFIG.REIBUS,
    rates: [],
  };

  const datB2CDataset: ChartData = {
    ...DATASET_CONFIG.DAT_SPOT,
    rates: [],
  };

  const datC2CDataset: ChartData = {
    ...DATASET_CONFIG.DAT_CONTRACT,
    rates: [],
    destination: { locationFit: undefined },
  };

  const greenscreensDataset: ChartData = {
    ...DATASET_CONFIG.GREENSCREENS,
    rates: [],
    destination: { locationFit: undefined },
  };

  historicalRates.forEach((rate) => {
    const chartRates = rate.monthlyRates.map((point) => {
      const date = new Date(point.date);
      const normalizedDate = new Date(
        Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1, 0, 0, 0, 0)
      );
      return {
        date: normalizedDate.toISOString(),
        rate: point.rate,
        high: point.highRate,
        low: point.lowRate,
      };
    });

    if (rate.source === 'REIBUS') {
      reibusDataset.rates = chartRates;
    } else if (rate.source === 'DAT' && rate.rateType === 'SPOT') {
      datB2CDataset.rates = chartRates;
      datB2CDataset.origin = { locationFit: rate.origin.locationFit };
      datB2CDataset.destination = { locationFit: rate.destination.locationFit };
    } else if (rate.source === 'DAT' && rate.rateType === 'CONTRACT') {
      datC2CDataset.rates = chartRates;
      datC2CDataset.origin = { locationFit: rate.origin.locationFit };
      datC2CDataset.destination = { locationFit: rate.destination.locationFit };
    } else if (rate.source === 'GREENSCREENS') {
      greenscreensDataset.rates = chartRates;
      greenscreensDataset.origin = { locationFit: rate.origin.locationFit };
      greenscreensDataset.destination = {
        locationFit: rate.destination.locationFit,
      };
    }
  });

  const datasets = [reibusDataset, datB2CDataset, datC2CDataset];

  const { ct1084GreenscreensLaneRateHistory } = useFlags();
  if (ct1084GreenscreensLaneRateHistory) {
    datasets.push(greenscreensDataset);
  }

  return (
    <Stack direction="column" gap={2}>
      <Stack direction="row" gap={1} alignItems="baseline">
        <Body size="large" weight="semibold">
          Lane Rate History:
        </Body>
        <Stack direction="row" gap={4}>
          <LocationDetails
            {...origin}
            equipmentType={equipmentType}
            isLoading={isLoadingMarketConditions}
            isOrigin
          />
          <LocationDetails
            {...destination}
            equipmentType={equipmentType}
            isLoading={isLoadingMarketConditions}
          />
        </Stack>
      </Stack>
      <Chart datasets={datasets} />
      <Stack>
        <Body size="small" color="#64676B">
          * Select Rate Source to View Rates on Chart for Comparison
        </Body>
      </Stack>
    </Stack>
  );
};

export default RateChart;
