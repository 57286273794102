import { Stack } from '@mui/material';
import { H5, H1, H3 } from 'components/SteelUI/atoms/typography';
import { formatRate } from './utils';
import {
  CurrentRate,
  RateSource,
} from 'features/LogisticsQuoteManager/hooks/useGetCurrentRates';

type Props = Pick<CurrentRate, 'source'> &
  Partial<Pick<CurrentRate, 'rate' | 'lowRate' | 'highRate'>>;

const sourceToTitle: Record<RateSource, string> = {
  DAT: 'DAT - BROKER TO CARRIER SPOT',
  REIBUS: 'REIBUS RATES',
  GREENSCREENS: 'GREENSCREENS',
};

const RateDetails = ({ source, rate, lowRate, highRate }: Props) => {
  return (
    <Stack direction="column">
      <H5 weight="medium" color="#64676B" textTransform="uppercase">
        {sourceToTitle[source]}
      </H5>
      <H5 color="#64676B">Includes Fuel</H5>
      <Stack
        direction="row"
        spacing="24px"
        paddingTop="12px"
        alignItems="baseline"
      >
        <H1 weight="semibold">{formatRate(rate)}</H1>
        <H3>
          ({formatRate(lowRate)}
          &nbsp;-&nbsp;
          {formatRate(highRate)})
        </H3>
      </Stack>
    </Stack>
  );
};

export default RateDetails;
