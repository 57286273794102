import { adaptV4Theme, createTheme } from '@mui/material';
import { secondary } from '@reibus/reibus-core-utils/dist/src/objects/dimensions/lotTypesByLabel';

const DEFAULT_FONT_COLOR = '#34363B';
const FONT_WEIGHT_REGULAR = 400;
const FONT_WEIGHT_BOLD = 700;
const FONT_WEIGHT_LIGHT = 300;

const PALETTE = {
  primary: {
    main: '#2D6CCB',
  },
  secondary: {
    main: '#EAF2FF',
  },
  error: {
    main: '#BB121A',
  },
  text: {
    primary: '#212224',
    secondary: '#64676B',
    disabled: '#CACDD1',
  },
  common: {
    white: '#FFFFFF',
    black: '#000000',
  },
  grey: {
    50: '#F6F7F7',
    100: '#EDEEF0',
    200: '#DCDEE0',
    300: '#CACDD1',
    400: '#B9BDC1',
    500: '#A7ACB2',
    600: '#868A8E',
    700: '#64676B',
    800: '#434547',
    900: '#212224',
  },
};

export const BREAKPOINTS = {
  values: {
    xs: 0,
    sm: 744,
    md: 1080,
    lg: 1440,
    xl: 1536,
  },
};

const baseTheme = {
  breakpoints: BREAKPOINTS,
  typography: {
    fontFamily: [
      `Montserrat`,
      `Lato`,
      `Mulish`,
      `-apple-system`,
      `BlinkMacSystemFont`,
      `"avenir next"`,
      `avenir`,
      `"helvetica neue"`,
      `helvetica`,
      `ubuntu`,
      `roboto`,
      `noto`,
      `"segoe ui"`,
      `arial`,
      `sans-serif`,
    ].join(','),
    // h1 is TITLE DESKTOP
    h1: {
      fontSize: 32,
      fontWeight: FONT_WEIGHT_BOLD,
      letterSpacing: 0,
      lineHeight: '52px',
      color: DEFAULT_FONT_COLOR,
    },
    // h2 is Heading 1 Desktop
    h2: {
      fontSize: 32,
      fontWeight: FONT_WEIGHT_BOLD,
      letterSpacing: 0,
      lineHeight: '52px',
      color: DEFAULT_FONT_COLOR,
    },
    // h3 is Heading 2 Desktop
    h3: {
      fontSize: 24,
      fontWeight: FONT_WEIGHT_BOLD,
      letterSpacing: 0,
      lineHeight: '40px',
      color: DEFAULT_FONT_COLOR,
    },
    // h4 is Heading 3 Desktop
    h4: {
      fontSize: 20,
      fontWeight: FONT_WEIGHT_REGULAR,
      letterSpacing: 0,
      lineHeight: '32px',
      color: DEFAULT_FONT_COLOR,
    },
    // subtitle1 is SUBHEAD 1
    subtitle1: {
      fontSize: 16,
      fontWeight: FONT_WEIGHT_LIGHT,
      letterSpacing: 2.25,
      lineHeight: '24px',
      color: DEFAULT_FONT_COLOR,
      textTransform: 'uppercase',
    },
    // subtitle2 is SUBHEAD 2
    subtitle2: {
      fontSize: 14,
      fontWeight: FONT_WEIGHT_LIGHT,
      letterSpacing: 1.18,
      lineHeight: '22px',
      color: DEFAULT_FONT_COLOR,
      textTransform: 'uppercase',
    },
    // body1 is Paragraph 1
    body1: {
      fontSize: 18,
      fontWeight: FONT_WEIGHT_REGULAR,
      letterSpacing: 0,
      lineHeight: '26px',
      color: DEFAULT_FONT_COLOR,
    },
    // body2 is Paragraph 2
    body2: {
      fontSize: 16,
      fontWeight: FONT_WEIGHT_REGULAR,
      letterSpacing: 0,
      lineHeight: '24px',
      color: DEFAULT_FONT_COLOR,
    },
    // caption is Paragraph 3
    caption: {
      fontSize: 14,
      fontWeight: FONT_WEIGHT_REGULAR,
      letterSpacing: 0,
      lineHeight: '22px',
      color: DEFAULT_FONT_COLOR,
    },
  },
  palette: PALETTE,
} as const;

const updatedTheme = {
  ...baseTheme,
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          borderColor: PALETTE.grey[100],
          backgroundColor: PALETTE.common.white,
          '&:hover label.MuiInputLabel-root': {
            color: PALETTE.primary.main,
          },
          '& label.MuiInputLabel-root': {
            lineHeight: 1.25,
            marginBottom: 0,
            transform: 'translate(14px, 11px) scale(1)',
            fontWeight: 500,
            color: PALETTE.text.primary,
            '&.Mui-focused': {
              color: PALETTE.primary.main,
            },
            '&.MuiInputLabel-shrink': {
              transform: 'translate(14px, -9px) scale(0.875)',
            },
            '&.Mui-error': {
              color: PALETTE.error.main,
            },
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              transition: 'border 0.2s ease-in-out',
            },
            '& legend': {
              fontSize: 14,
            },
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
        sx: {
          height: '2.625rem',
          paddingRight: 1,
          '&:focus-within fieldset.MuiOutlinedInput-notchedOutline': {
            borderWidth: 2,
            borderColor: PALETTE.primary.main,
          },
        },
        MenuProps: {
          PaperProps: {
            style: {
              marginTop: 8,
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '8px !important',
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: PALETTE.common.white,
          },
          '&::-webkit-scrollbar-thumb': {
            width: '4px',
            border: `2px solid ${PALETTE.common.white}`,
            borderRadius: 8,
            backgroundColor: PALETTE.grey[300],
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: PALETTE.text.secondary,
          '&:hover': {
            fontWeight: 700,
            color: PALETTE.text.primary,
            backgroundColor: PALETTE.secondary.main,
          },
          '&.Mui-selected': {
            fontWeight: 700,
            color: PALETTE.text.primary,
            backgroundColor: PALETTE.secondary.main,
          },
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          borderColor: PALETTE.grey[100],
        },
      },
    },
  },
} as const;

export const oldTheme = createTheme({
  ...adaptV4Theme(baseTheme),
});

export const newTheme = createTheme(updatedTheme);

export default newTheme;
export { FONT_WEIGHT_BOLD };
