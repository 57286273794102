import { UserContextProvider } from 'app/UserContext';
import { StrictMode } from 'react';
import App from '../app';
import CssBaseline from '@mui/material/CssBaseline';
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles';
import { oldTheme } from '../theme';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import BUILD from 'build.json';
import ScrollToTop from '../utils/ScrollToTop';
import { createGenerateClassName, StylesProvider } from '@mui/styles';
import { queryClient, ldClient } from '@reibus/frontend-utility';
import { LDProvider } from 'launchdarkly-react-client-sdk';
declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const generateClassName = createGenerateClassName({
  productionPrefix: 'rlf',
});

const Entry = () => {
  return (
    <StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={oldTheme}>
          <StylesProvider generateClassName={generateClassName}>
            <CssBaseline />
            <Router basename={process.env.PUBLIC_URL}>
              <ScrollToTop />
              <LDProvider
                clientSideID={
                  process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID ?? ''
                }
                ldClient={ldClient}
              >
                <QueryClientProvider client={queryClient}>
                  <HelmetProvider>
                    <Helmet>
                      <meta
                        name="logistics-ui-version"
                        content={BUILD.VERSION}
                      />
                    </Helmet>
                    <UserContextProvider>
                      <App />
                    </UserContextProvider>
                  </HelmetProvider>
                  <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
              </LDProvider>
            </Router>
          </StylesProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </StrictMode>
  );
};

export default Entry;
