import { Divider, Stack, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { H1, H3, H4, H5 } from 'components/SteelUI/atoms/typography';
import { displayFormattingValue } from '@reibus/reibus-core-utils';
import TargetRate from './TargetRate';

import type { CurrentRate } from 'features/LogisticsQuoteManager/hooks/useGetCurrentRates';
import RateDetails from './RateDetails';
import { formatRate } from './utils';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  mileage: {
    marginTop: spacing(2),
  },
}));

type Props = {
  datRate?: CurrentRate;
  reibusRate?: CurrentRate;
  greenscreensRate?: CurrentRate;
  distance?: number;
  freightQuoteRequestId?: string;
};

const getAverage = (
  fieldToAverage: 'lowRate' | 'highRate',
  datRate?: CurrentRate,
  reibusRate?: CurrentRate,
  greenscreensRate?: CurrentRate
) => {
  const valuesToAverage = [
    datRate?.[fieldToAverage],
    reibusRate?.[fieldToAverage],
    greenscreensRate?.[fieldToAverage],
  ].filter((rate): rate is number => rate !== undefined);

  if (!valuesToAverage.length) {
    return { displayValue: 'N/A', value: 0 };
  }
  const average =
    valuesToAverage.reduce((sum, rate) => sum + rate, 0) /
    valuesToAverage.length;
  return { displayValue: `${formatRate(average)}`, value: average };
};

const Rates = ({
  datRate,
  reibusRate,
  greenscreensRate,
  distance,
  freightQuoteRequestId,
}: Props) => {
  const { ct795GreenscreensCurrentRateComparison } = useFlags();
  const classes = useStyles();

  const blendedLow = getAverage(
    'lowRate',
    datRate,
    reibusRate,
    !ct795GreenscreensCurrentRateComparison ? undefined : greenscreensRate
  );
  const blendedHigh = getAverage(
    'highRate',
    datRate,
    reibusRate,
    !ct795GreenscreensCurrentRateComparison ? undefined : greenscreensRate
  );
  const blendedAverage = Math.round((blendedHigh.value + blendedLow.value) / 2);

  return (
    <Stack direction="column" padding="24px 0 0 0" spacing="24px">
      <RateDetails source="DAT" {...datRate} />
      {!ct795GreenscreensCurrentRateComparison ? null : (
        <RateDetails source="GREENSCREENS" {...greenscreensRate} />
      )}
      <RateDetails source="REIBUS" {...reibusRate} />
      <Divider />
      <Stack direction="column">
        <H5 color="#64676B">BLENDED TARGET RATE RANGE</H5>
        <H1 weight="semibold">
          {blendedLow.displayValue} - {blendedHigh.displayValue}
        </H1>
        <H4 weight="semibold" color="#64676B" className={classes.mileage}>
          Mileage
        </H4>
        <H4>
          {!distance
            ? 'N/A'
            : displayFormattingValue(distance, {
                trailingZeros: false,
                decimalPlaces: 0,
              }) ?? 'N/A'}
        </H4>
      </Stack>
      <TargetRate
        blendedAverage={blendedAverage}
        freightQuoteRequestId={freightQuoteRequestId}
      />
    </Stack>
  );
};

export default Rates;
