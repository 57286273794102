import { stringValidator } from 'components/library/form/fieldValidators';
import { InputField } from 'components/SteelUI/molecules';
import { useField, useFormState } from 'react-final-form';

import type {
  FilledInputProps,
  InputProps,
  OutlinedInputProps,
} from '@mui/material';

type Props = {
  name: string;
  label: string;
  required?: boolean;
  validator?: (value: string) => string | undefined;
  InputProps?:
    | Partial<InputProps>
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>;
  disabled?: boolean;
  value?: string | number;
};

const TextField = ({
  name,
  label,
  required,
  validator,
  InputProps,
  disabled = false,
  value,
}: Props) => {
  const { submitFailed } = useFormState();
  const { input, meta } = useField(name, {
    validate: (value) => {
      const requiredError = required ? stringValidator(value) : undefined;
      if (validator) {
        return requiredError || validator(value);
      }
      return requiredError;
    },
  });
  const showError = meta.error && submitFailed;

  return (
    <InputField
      label={label}
      name={name}
      value={value ?? input.value}
      onChange={input.onChange}
      onBlur={input.onBlur}
      required={required}
      error={showError ? meta.error : undefined}
      helperText={showError ? meta.error : undefined}
      InputProps={InputProps}
      disabled={disabled}
    />
  );
};

export default TextField;
