import { ChangeEvent } from 'react';
import { makeStyles } from '@mui/styles';
import {
  FormControlLabel as MuiFormControlLabel,
  RadioGroup as MuiRadioGroup,
  FormLabel as MuiFormLabel,
  Stack,
} from '@mui/material';
import { Typography } from '@mui/material';
import CustomRadio from '../atoms/Radio';

const useRadioStyle = makeStyles(() => ({
  asterisk: {
    color: '#c62828',
    fontSize: '1.2rem',
  },
}));

export type RadioGroupProps = {
  label: string;
  options: {
    value: string;
    label: string;
  }[];
  required?: boolean;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const RadioGroup = ({
  label,
  options,
  required = false,
  value,
  onChange,
}: RadioGroupProps) => {
  const classes = useRadioStyle();

  return (
    <Stack direction="column" spacing={2}>
      <MuiFormLabel
        id="demo-radio-buttons-group-label"
        required={required}
        classes={{
          asterisk: classes.asterisk,
        }}
      >
        {label}
      </MuiFormLabel>
      <MuiRadioGroup name={label} value={value} onChange={onChange} row>
        {options.map((option) => {
          const { value, label } = option;
          return (
            <MuiFormControlLabel
              value={value}
              key={label}
              control={<CustomRadio />}
              label={<Typography variant="caption">{label}</Typography>}
            />
          );
        })}
      </MuiRadioGroup>
    </Stack>
  );
};

export default RadioGroup;
