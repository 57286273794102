import { Skeleton, Stack } from '@mui/material';
import Body from 'components/SteelUI/atoms/typography/Body';
import { getCapacityLabel } from './utils';
import { useFlags } from 'launchdarkly-react-client-sdk';

type Props = {
  city?: string;
  state?: string;
  loadToTruckRatio?: number;
  mciScore?: number;
  equipmentType?: number;
  isLoading: boolean;
  isOrigin?: boolean;
};

const LocationDetails = ({
  city,
  state,
  loadToTruckRatio,
  mciScore,
  equipmentType,
  isLoading,
  isOrigin,
}: Props) => {
  const { ct1004LoadToTruckRatio } = useFlags();
  const locationLabel = `${city}, ${state}`;
  const mciScoreLabel = getCapacityLabel({ equipmentType, mciScore });
  const loadToTruckRatioLabel = `Outbound Prior Biz Day L/T Ratio: ${
    !loadToTruckRatio ? 'N/A' : loadToTruckRatio
  }`;
  return (
    <Stack direction="column" gap={2}>
      <Stack direction="row" justifyContent="space-between" gap={4}>
        <Body weight="medium" textTransform="uppercase">
          {locationLabel}
        </Body>
        {!isOrigin ? null : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="95"
            height="16"
            viewBox="0 0 95 16"
            fill="none"
          >
            <path
              d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9L1 7ZM94.7071 8.70711C95.0976 8.31658 95.0976 7.68342 94.7071 7.29289L88.3431 0.928932C87.9526 0.538408 87.3195 0.538408 86.9289 0.928932C86.5384 1.31946 86.5384 1.95262 86.9289 2.34315L92.5858 8L86.9289 13.6569C86.5384 14.0474 86.5384 14.6805 86.9289 15.0711C87.3195 15.4616 87.9526 15.4616 88.3431 15.0711L94.7071 8.70711ZM4.875 9C5.42728 9 5.875 8.55228 5.875 8C5.875 7.44772 5.42728 7 4.875 7V9ZM12.625 7C12.0727 7 11.625 7.44772 11.625 8C11.625 8.55228 12.0727 9 12.625 9V7ZM20.375 9C20.9273 9 21.375 8.55228 21.375 8C21.375 7.44772 20.9273 7 20.375 7V9ZM28.125 7C27.5727 7 27.125 7.44772 27.125 8C27.125 8.55228 27.5727 9 28.125 9V7ZM35.875 9C36.4273 9 36.875 8.55228 36.875 8C36.875 7.44772 36.4273 7 35.875 7V9ZM43.625 7C43.0727 7 42.625 7.44772 42.625 8C42.625 8.55228 43.0727 9 43.625 9V7ZM51.375 9C51.9273 9 52.375 8.55228 52.375 8C52.375 7.44772 51.9273 7 51.375 7V9ZM59.125 7C58.5727 7 58.125 7.44772 58.125 8C58.125 8.55228 58.5727 9 59.125 9V7ZM66.875 9C67.4273 9 67.875 8.55228 67.875 8C67.875 7.44772 67.4273 7 66.875 7V9ZM74.625 7C74.0727 7 73.625 7.44772 73.625 8C73.625 8.55228 74.0727 9 74.625 9V7ZM82.375 9C82.9273 9 83.375 8.55228 83.375 8C83.375 7.44772 82.9273 7 82.375 7V9ZM90.125 7C89.5727 7 89.125 7.44772 89.125 8C89.125 8.55228 89.5727 9 90.125 9V7ZM1 9H4.875V7H1L1 9ZM12.625 9L20.375 9V7H12.625V9ZM28.125 9H35.875V7H28.125V9ZM43.625 9H51.375V7L43.625 7V9ZM59.125 9H66.875V7H59.125V9ZM74.625 9L82.375 9V7L74.625 7V9ZM90.125 9H94V7H90.125V9Z"
              fill="#CACDD1"
            />
          </svg>
        )}
      </Stack>
      {!ct1004LoadToTruckRatio ? null : (
        <Stack direction="column">
          <Body size="small">{isLoading ? <Skeleton /> : mciScoreLabel}</Body>
          <Body size="small">
            {isLoading ? (
              <Stack direction="row">
                Outbound Prior Biz Day L/T Ratio:{' '}
                <Skeleton
                  component="span"
                  width={24}
                  sx={{ display: 'inline-block' }}
                />
              </Stack>
            ) : (
              loadToTruckRatioLabel
            )}
          </Body>
        </Stack>
      )}
    </Stack>
  );
};

export default LocationDetails;
