import { forwardRef } from 'react';

import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  TextField as MuiTextField,
  TextFieldProps,
  Typography,
  styled,
} from '@mui/material';

const StyledTextField = styled(MuiTextField)(({ theme: { palette } }) => ({
  '& label.MuiInputLabel-root': {
    color: '#212224',
    fontWeight: 400,
    whiteSpace: 'normal',
    '&.MuiInputLabel-shrink': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: 'calc(100% - 8px)',
      textOverflow: 'ellipsis',
    },
    '&.Mui-error:not(.MuiInputLabel-shrink)': {
      color: palette.text.primary,
    },
    '&.MuiInputLabel-shrink .label-required-icon': {
      fontWeight: 700,
    },
    '&.MuiInputLabel-shrink.Mui-error': {
      fontWeight: 700,
      color: 'red',
    },
  },
  '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
    borderColor: palette.grey[100],
  },
  '& .MuiInputBase-input:not(textarea)': {
    padding: '9.5px 16px',
  },
  '& .MuiOutlinedInput-root': {
    borderColor: palette.grey[100],
    '&.Mui-disabled fieldset': {
      backgroundColor: 'rgba(246, 247, 247, 0.5)',
      borderColor: `${palette.grey[100]} !important`,
    },
  },
  '& .MuiOutlinedInput-root:not(.Mui-disabled)': {
    '&:hover': {
      '.MuiInputAdornment-root': {
        color: palette.primary.main,
      },
      fieldset: {
        borderColor: palette.primary.main,
        borderWidth: 2,
      },
    },
    '&.Mui-error fieldset': {
      borderColor: palette.error.main,
      borderWidth: 2,
    },
  },
  '& .MuiInputBase-root.Mui-disabled .MuiInputBase-input': {
    color: `${palette.text.primary} !important`,
    WebkitTextFillColor: `${palette.text.primary} !important`,
  },
  '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
    {
      WebkitAppearance: 'none',
      margin: 0,
    },
  '& input[type="number"]': {
    MozAppearance: 'textfield',
  },
}));

type SupportingTextProps = {
  error?: boolean;
  disabled?: boolean;
};

const SupportingText = styled(Typography)<SupportingTextProps>(
  ({ error, disabled, theme: { palette } }) => ({
    padding: '0 1rem',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.25,
    color: error
      ? palette.error.main
      : disabled
      ? palette.text.disabled
      : palette.text.primary,
    '& .supporting-text-error-icon': {
      paddingRight: 8,
      '& svg': {
        verticalAlign: '-0.2em', // line up inline icon with text
      },
    },
  })
);

const SupportingTextErrorIcon = () => (
  <span className="supporting-text-error-icon">
    <FontAwesomeIcon icon={faCircleExclamation} color="inherit" fontSize={16} />
  </span>
);

const InputField = forwardRef<
  HTMLDivElement,
  TextFieldProps & { overrideDefaultRequiredLabel?: boolean }
>(
  (
    {
      helperText: supportingText,
      required,
      label,
      error,
      disabled,
      overrideDefaultRequiredLabel = false,
      ...props
    },
    ref
  ) => {
    const displayLabel =
      required && !overrideDefaultRequiredLabel ? (
        <>
          <span className="label-required-icon">*&nbsp;</span>
          {label}
        </>
      ) : (
        label
      );

    return (
      <Box display="flex" flexDirection="column" gap={1}>
        <StyledTextField
          {...props}
          ref={ref}
          label={displayLabel}
          error={error}
          disabled={disabled}
          variant="outlined"
          fullWidth
        />
        {supportingText ? (
          <SupportingText error={error} disabled={disabled}>
            {error ? <SupportingTextErrorIcon /> : null}
            {supportingText}
          </SupportingText>
        ) : null}
      </Box>
    );
  }
);

InputField.displayName = 'TextField';

export default InputField;
