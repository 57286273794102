import { useEffect, useState } from 'react';
import LocationSearch from 'components/SteelUI/molecules/LocationSearch';
import Button from 'components/SteelUI/atoms/Button';
import { Box, Grid, Stack } from '@mui/material';
import { useField, useFormState } from 'react-final-form';
import TextField from './TextField';
import CountrySelect from './CountrySelect';
import { RegionSelect } from './RegionSelect';

type AddressParts = {
  long_name: string;
  short_name: string;
  types: string[];
};
export type AddressBreakdown = {
  addressParts: AddressParts[];
  geometry?: google.maps.places.PlaceGeometry;
};

export type GoogleAutoCompleteProps = {
  name: string;
  label: string;
  required: boolean;
  placesServiceInstance: google.maps.places.PlacesService | null;
};

type AddressFieldProps = {
  showManualLabel?: boolean;
} & GoogleAutoCompleteProps;

export const AddressField = ({
  name,
  required = false,
  showManualLabel = true,
  label,
  placesServiceInstance,
}: AddressFieldProps) => {
  const [isHidden, setIsHidden] = useState(true);
  const { submitFailed } = useFormState();

  const toggleHidden = () => {
    setIsHidden(!isHidden);
  };

  const { input, meta } = useField(`${name}Breakdown`, {
    validate: (value, allValues: Record<string, any>) => {
      const hasAddressFields =
        allValues[`${name}_address1`] &&
        allValues[`${name}_city`] &&
        allValues[`${name}_postalCode`] &&
        allValues[`${name}_region`] &&
        allValues[`${name}_country`];

      return required && !value && !hasAddressFields ? 'Required' : undefined;
    },
  });

  const { input: placeIdInput } = useField(`${name}PlaceId`);

  useEffect(() => {
    if (placeIdInput.value) {
      placesServiceInstance?.getDetails(
        {
          placeId: placeIdInput.value,
        },
        (placeDetails) => {
          if (placeDetails?.address_components) {
            input.onChange({
              addressParts: placeDetails?.address_components,
              geometry: placeDetails?.geometry,
            });
          }
        }
      );
    }
  }, []);

  const handleChange = (value?: string) => {
    if (!value) {
      input.onChange(undefined);
      placeIdInput.onChange(undefined);
      return;
    }

    const placeId = value;
    placesServiceInstance?.getDetails(
      {
        placeId,
      },
      (placeDetails) => {
        if (placeDetails?.address_components) {
          input.onChange({
            addressParts: placeDetails?.address_components,
            geometry: placeDetails?.geometry,
          });
          placeIdInput.onChange(placeId);
        }
      }
    );
  };

  const handleInputChange = (inputValue: string) => {
    if (!inputValue) {
      placeIdInput.onChange(undefined);
      input.onChange(undefined);
      return;
    }
    if (placeIdInput.value) {
      placeIdInput.onChange(undefined);
    }
  };

  const showError = meta.error && submitFailed;

  return (
    <Stack direction="column" width="100%">
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Box sx={{ flex: 1, marginRight: '24px' }}>
          {isHidden ? (
            <LocationSearch
              label={label ?? 'Address'}
              initialPlaceId={placeIdInput.value}
              required
              searchTypes={['(regions)', '(cities)']}
              onChange={handleChange}
              onBlur={input.onBlur}
              error={showError ? meta.error : undefined}
              onInputChange={handleInputChange}
            />
          ) : (
            <TextField
              label="Street Address"
              name={`${name}_address1`}
              required
            />
          )}
        </Box>
        {!showManualLabel ? null : (
          <Box>
            <Button
              variant="outlined"
              onClick={toggleHidden}
              toggle
              sx={{ whiteSpace: 'nowrap', px: 4 }}
            >
              Enter Manually
            </Button>
          </Box>
        )}
      </Box>
      {isHidden ? null : (
        <Grid container spacing={2} sx={{ mt: '1px' }}>
          <Grid item md={6} sm={12}>
            <CountrySelect name={name} />
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField label="City" name={`${name}_city`} required />
          </Grid>
          <Grid item md={6} sm={12}>
            <RegionSelect name={name} />
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField label="Zip" name={`${name}_postalCode`} required />
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};
