import { Box, Card, Stack } from '@mui/material';
import PageTitle from 'components/PageTitle';
import PageWrapper from 'components/PageWrapper';
import { Body, H1, H2 } from 'components/SteelUI/atoms/typography';
import { ModalContainer } from 'components/SteelUI/molecules/modals';
import ModalCloseIcon from 'components/SteelUI/molecules/modals/components/ModalIcons/ModalCloseIcon';
import ModalErrorIcon from 'components/SteelUI/molecules/modals/components/ModalIcons/ModalErrorIcon';
import RateChart from 'features/LogisticsQuoteManager/components/RateChart';
import RatesComparison from 'features/LogisticsQuoteManager/components/RatesComparison';
import type { RatesRequest } from 'features/LogisticsQuoteManager/hooks/useGetCurrentRates';
import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Form from './components/Form';
import useGetCurrentRates from './hooks/useGetCurrentRates';
import useGetHistoricalRates from './hooks/useGetHistoricalRates';
import useGetMarketConditions, {
  GetMarketConditionsParams,
} from './hooks/useGetMarketConditions';

const useStyles = makeStyles()(() => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1fr)',
    gridTemplateRows: 'auto 1fr',
    gap: '1rem',
    width: '100%',
    height: '100%',
    padding: '24px',
  },
  card: {
    padding: '24px',
  },
  cardForm: {
    gridColumn: '1 / span 10',
    gridRow: '1',
  },
  cardChart: {
    gridColumn: '1 / span 7',
    gridRow: '2',
    minHeight: '620px',
  },
  cardComparison: {
    gridColumn: '8 / span 3',
    gridRow: '2',
  },
}));

const LogisticsQuoteManager = () => {
  const { cx, classes } = useStyles();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [formData, setFormData] = useState<RatesRequest>();

  const [originMarketConditionsParams, setOriginMarketConditionsParams] =
    useState<GetMarketConditionsParams | null>(null);
  const [
    destinationMarketConditionsParams,
    setDestinationMarketConditionsParams,
  ] = useState<Partial<GetMarketConditionsParams> | null>(null);

  const {
    mutate: getCurrentRates,
    isLoading: isLoadingCurrentRates,
    data: { currentRates = [], distance = 0, freightQuoteRequestId = '' } = {},
    error: errorCurrentRates,
    isIdle: hasNeverFetchedCurrentRates,
  } = useGetCurrentRates();

  const {
    mutate: getHistoricalRates,
    isLoading: isLoadingHistoricalRates,
    data: { historicalRates = [] } = {},
    error: errorGetHistoricalRates,
    isIdle: hasNeverFetchedHistoricalRates,
  } = useGetHistoricalRates();

  const {
    data: originMarketConditions = {},
    error: errorOriginMarketConditions,
    isLoading: isLoadingOriginMarketConditions,
  } = useGetMarketConditions({
    params: originMarketConditionsParams,
    enabled: !!originMarketConditionsParams,
  });

  const {
    data: destinationMarketConditions = {},
    error: errorDestinationMarketConditions,
    isLoading: isLoadingDestinationMarketConditions,
  } = useGetMarketConditions({
    params: destinationMarketConditionsParams,
    enabled: !!destinationMarketConditionsParams,
  });

  const handleGetRate = (data: RatesRequest) => {
    getCurrentRates(data);
    getHistoricalRates(data);
    setFormData(data);
    setOriginMarketConditionsParams(null);
    setDestinationMarketConditionsParams(null);
  };

  const resetMarketConditionsParams = () => {
    setOriginMarketConditionsParams(null);
    setDestinationMarketConditionsParams(null);
  };

  useEffect(() => {
    if (!historicalRates.length || !formData) {
      resetMarketConditionsParams();
      return;
    }

    const datSpot = historicalRates.find(
      (rate) => rate.source === 'DAT' && rate.rateType === 'SPOT'
    );

    if (!datSpot) {
      resetMarketConditionsParams();
      return;
    }

    setOriginMarketConditionsParams({
      city: formData.origin?.city,
      state: formData.origin?.state,
      equipmentType: formData.equipmentType,
      locationFit: datSpot.origin?.locationFit,
    });

    setDestinationMarketConditionsParams({
      city: formData.destination?.city,
      state: formData.destination?.state,
      equipmentType: formData.equipmentType,
      locationFit: datSpot.destination?.locationFit,
    });
  }, [historicalRates, formData]);

  useEffect(() => {
    const hasError =
      errorCurrentRates ||
      errorGetHistoricalRates ||
      errorOriginMarketConditions ||
      errorDestinationMarketConditions;

    if (hasError) {
      setOpenErrorModal(true);
    }
  }, [
    errorCurrentRates,
    errorGetHistoricalRates,
    errorOriginMarketConditions,
    errorDestinationMarketConditions,
  ]);

  return (
    <PageWrapper showFooter={false} title="Logistics Quote Manager">
      <PageTitle
        title="Logistics Quote Manager"
        description="Get Started By Inputting Your Shipment Information"
      />
      <Box className={cx(classes.gridContainer)}>
        <Card
          sx={{ padding: '24px' }}
          variant="outlined"
          className={classes.cardForm}
        >
          <Form getRate={handleGetRate} />
        </Card>
        <Card
          variant="outlined"
          className={cx(classes.card, classes.cardChart)}
        >
          <RateChart
            hasNeverFetched={hasNeverFetchedHistoricalRates}
            isLoadingHistoricalRates={isLoadingHistoricalRates}
            historicalRates={historicalRates}
            origin={{
              city: formData?.origin?.city,
              state: formData?.origin?.state,
              loadToTruckRatio: originMarketConditions?.loadToTruckRatio,
              mciScore: originMarketConditions?.mciScore,
            }}
            destination={{
              city: formData?.destination?.city,
              state: formData?.destination?.state,
              loadToTruckRatio: destinationMarketConditions?.loadToTruckRatio,
              mciScore: destinationMarketConditions?.mciScore,
            }}
            equipmentType={formData?.equipmentType}
            isLoadingMarketConditions={
              isLoadingOriginMarketConditions ||
              isLoadingDestinationMarketConditions
            }
          />
        </Card>
        <Card
          variant="outlined"
          className={cx(classes.card, classes.cardComparison)}
        >
          <RatesComparison
            hasNeverFetched={hasNeverFetchedCurrentRates}
            isLoading={isLoadingCurrentRates}
            currentRates={currentRates}
            distance={distance}
            freightQuoteRequestId={freightQuoteRequestId}
          />
        </Card>
      </Box>
      <ModalContainer open={openErrorModal}>
        <Box
          sx={{
            backgroundColor: '#FFF',
            borderRadius: '8px',
            padding: '24px',
          }}
        >
          <Stack direction="column" alignItems="end">
            <ModalCloseIcon onClick={() => setOpenErrorModal(false)} />
            <Stack direction="column" alignItems="center">
              <H1>
                <ModalErrorIcon />
              </H1>
              <H2 paddingTop="8px">An error has occurred</H2>
              <Body>Please try again or contact support.</Body>
            </Stack>
          </Stack>
        </Box>
      </ModalContainer>
    </PageWrapper>
  );
};

export default LogisticsQuoteManager;
