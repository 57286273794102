import { useUserContext } from 'app/UserContext';
import { useState, useEffect, memo } from 'react';
import PageWrapper from 'components/PageWrapper';
import { makeStyles } from '@mui/styles';
import { Theme, Grid, useMediaQuery } from '@mui/material';
import ShipmentsListContainer from './ShipmentListContainer';
import {
  FormSubmitMessage,
  Message,
} from 'components/library/form/FormSubmitMessage';
import { usePageView } from 'utils/mixpanel';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import { algoliaClient } from 'utils/algolia';
import ShipmentStatus from './ShipmentStatus';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ShipmentTabOption } from '../components/library/ToggleBar';
import { useUserSession } from '@reibus/frontend-utility';
import { Title } from 'components/SteelUI/atoms/typography';

const ALGOLIA_INDEX_NAME = 'shipments';
const ALGOLIA_INDEX_NAME_PICKUP_DATE_DESC = 'shipments_pickupDate_desc';

export const HITS_PER_PAGE = 20;

const useStyles = makeStyles((theme: Theme) => ({
  pageWrap: {
    position: 'relative',
    backgroundColor: '#f5f5f5',
    paddingBottom: theme.spacing(4),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  header: {
    zIndex: 1,
    padding: `${theme.spacing(2)} ${theme.spacing(3)} 0`,
  },
  [theme.breakpoints.up(2000)]: {
    pageWrap: {
      margin: `0 ${theme.spacing(30)}`,
    },
  },
  [theme.breakpoints.down(900)]: {
    pageWrap: {
      backgroundColor: '#fff',
    },
    header: {
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    },
  },
  [theme.breakpoints.down('sm')]: {
    header: {
      padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    },
    gridContainer: {
      padding: 0,
    },
  },
}));

export const generateInitConfigFacetFilters = (
  companyId: string,
  isReibusUser: boolean,
  featureFlagAllShipments: boolean
) => {
  const excludedShipmentStatusFilterString = `NOT ${ShipmentStatus.Excluded.map(
    ({ code }) => `statusCode:${code}`
  ).join(' AND NOT ')}`;

  if (isReibusUser && featureFlagAllShipments) {
    return excludedShipmentStatusFilterString;
  }

  return `reibusID:${companyId} AND ${excludedShipmentStatusFilterString}`;
};

// IDEA: need an in-between component to just handle loading the user info
const ShipmentVisibilityDashboard = () => {
  const { user, isLoading } = useUserContext();
  const { data: userSession } = useUserSession();
  const { cognitoGroups = [] } = userSession || {};
  const isReibusUser = cognitoGroups.includes('reibus');
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [message, setMessage] = useState<Message | undefined>();
  const [companyId, setCompanyId] = useState<string>('');
  const [initPageDataLoaded, setInitPageDataLoaded] = useState<boolean>(false);
  const [currentStatusRefinement, setCurrentStatusRefinement] = useState(
    ShipmentTabOption.IN_PROGRESS
  );

  const classes = useStyles();
  const { rl2147AllShipmentsInReibusUserSvd } = useFlags();
  // fix me, this media query needs to be at some root level component or util file
  const mobile = useMediaQuery('(max-width: 1175px)');

  // this should run whenever we fail while grabbing the user
  const handleErrorDialogOpen = (err: Error) => {
    setMessage({
      title: (
        <Grid container justifyContent="center">
          Error
        </Grid>
      ),
      message: (
        <>
          <p>Error retrieving shipments: {err.message}</p>
        </>
      ),
    });
    setMessageDialogOpen(true);
  };

  const handleMessageDialogClose = () => {
    setMessageDialogOpen(false);
    setMessage(undefined);
  };

  usePageView(`Page View: Shipment Visibility Dashboard`, {
    currentTab: 'shipment-visibility-dashboard',
  });

  useEffect(() => {
    if (initPageDataLoaded || isLoading) {
      return;
    }

    if (!user.companyId) {
      const companyFetchError =
        'Issue Fetching Customer ID. Please refresh and try again. If this error persists please contact us at (888) 858-0648';
      handleErrorDialogOpen(new Error(companyFetchError));
    } else {
      setCompanyId(user.companyId);
      setInitPageDataLoaded(true);
    }
  }, [isLoading]);

  const listIsReadyToLoad = initPageDataLoaded && companyId;

  return (
    <PageWrapper showShortFooter isBackColorWhite={mobile}>
      <div className={classes.pageWrap}>
        <Grid container>
          <header className={classes.header}>
            <div>
              <Title weight="bold">Shipment Visibility Dashboard</Title>
            </div>
            <p>
              Email us at{' '}
              <a
                className={classes.link}
                href="mailto:shippersupport@reibus.com"
              >
                shippersupport@reibus.com
              </a>{' '}
              or give us a call at{' '}
              <a className={classes.link} href="tel:+18888580648">
                (888) 858-0648
              </a>{' '}
              if you need assistance or have any questions.
            </p>
          </header>
          {listIsReadyToLoad ? (
            <InstantSearch
              searchClient={algoliaClient}
              indexName={
                currentStatusRefinement === ShipmentTabOption.IN_PROGRESS
                  ? ALGOLIA_INDEX_NAME
                  : ALGOLIA_INDEX_NAME_PICKUP_DATE_DESC
              }
            >
              <Configure
                filters={generateInitConfigFacetFilters(
                  companyId,
                  isReibusUser,
                  rl2147AllShipmentsInReibusUserSvd
                )}
                hitsPerPage={HITS_PER_PAGE}
              />
              <ShipmentsListContainer
                handleCurrentStatusList={setCurrentStatusRefinement}
              />
            </InstantSearch>
          ) : null}
        </Grid>
      </div>

      {message ? (
        <FormSubmitMessage
          open={messageDialogOpen}
          message={message}
          onClose={handleMessageDialogClose}
        />
      ) : null}
    </PageWrapper>
  );
};

export default memo(ShipmentVisibilityDashboard);
