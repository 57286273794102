import Dropdown from 'components/SteelUI/molecules/dropdowns/Dropdown';
import { CurrentCountries } from 'components/library/form/AddressFieldTypes';
import { useField } from 'react-final-form';
import Flag from './components/Flag';
import { Box } from '@mui/material';

const CountrySelect = ({ name }: { name: string }) => {
  const { input, meta } = useField(`${name}_country`, {
    validate: (value, allValues: Record<string, any>) =>
      !value && !allValues[`${name}Breakdown`] ? 'Required' : undefined,
  });

  // Region input field should be reset when country is changed
  // See onChange below
  const { input: regionInput } = useField(`${name}_region`, {
    subscription: { value: true },
  });

  return (
    <>
      <Dropdown
        onChange={(e) => {
          input.onChange(e);
          regionInput.onChange(undefined);
        }}
        label="Country"
        required
        value={input.value}
        options={
          CurrentCountries?.map(({ id, label }) => ({
            label,
            value: id,
            icon: (
              <Box sx={{ paddingRight: '1rem' }}>
                <Flag country={id} />
              </Box>
            ),
          })) || []
        }
        error={meta.touched ? meta.error : undefined}
      />
    </>
  );
};

export default CountrySelect;
