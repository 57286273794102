import { displayFormattingValue } from '@reibus/reibus-core-utils';
import { LaneRateLocationFit } from 'features/LogisticsQuoteManager/hooks/useGetHistoricalRates';

/**
 * Calculates the appropriate y-axis domain and tick values for the chart
 *
 * @param allRates - Array of all rate values to be displayed
 * @returns Object containing yDomain, yTicks, and yTickInterval
 */
export const calculateYAxisTicks = (allRates: number[]) => {
  if (allRates.length === 0) {
    return { yDomain: [0, 1000], yTicks: [0, 500, 1000], yTickInterval: 500 };
  }

  const intervalMultiple = 100;
  const maxTickCount = 11;

  const yMinValue = Math.min(...allRates);
  const yMaxValue = Math.max(...allRates);

  // Round to nearest intervalMultiple below and above min/max
  const yMinDomain =
    Math.floor((yMinValue - intervalMultiple) / intervalMultiple) *
      intervalMultiple +
    intervalMultiple;
  const yMaxDomain =
    Math.ceil((yMaxValue + intervalMultiple) / intervalMultiple) *
      intervalMultiple -
    intervalMultiple;
  const yDomain = [yMinDomain, yMaxDomain];

  const yRange = yMaxDomain - yMinDomain;

  const yTickInterval = Math.max(
    intervalMultiple,
    Math.ceil(yRange / maxTickCount / intervalMultiple) * intervalMultiple
  );

  const yTicks = Array.from(
    { length: Math.floor(yRange / yTickInterval) + 1 },
    (_, i) => yMinDomain + i * yTickInterval
  );

  return { yDomain, yTicks, yTickInterval };
};

/**
 * Formats a rate value as a currency string
 *
 * @param rate - The rate value to format
 * @returns Formatted rate as USD currency string
 */
export const formatRate = (rate: number) => {
  return displayFormattingValue(
    rate,
    {
      trailingZeros: true,
      decimalPlaces: 0,
    },
    'USD'
  );
};

/**
 * Formats the location fit value into a user-friendly string
 *
 * @param locationFit - The location fit enum value
 * @returns Formatted location fit string
 */
export const formatLocationFit = (locationFit: LaneRateLocationFit) => {
  const locationFitMap: Record<LaneRateLocationFit, string> = {
    '3_DIGIT_ZIP': '3 Digit Zip',
    MARKET_AREA: 'Market Area',
    EXTENDED_MARKET_AREA: 'X-MKT',
    REGION: 'Region',
    STATE: 'State',
    '100_MILE_RADIUS': '100 Mile Radius',
  };

  return locationFitMap[locationFit];
};

const CAPACITY_LABELS = [
  'Very Loose Capacity',
  'Moderately Loose Capacity',
  'Slightly Loose Capacity',
  'Neutral Market',
  'Slightly Tight Capacity',
  'Moderately Tight Capacity',
  'Very Tight Capacity',
] as const;

type CapacityLabel = (typeof CAPACITY_LABELS)[number];

const MCI_SCORE_CONFIG: Record<
  'VAN' | 'FLATBED',
  { min: number; max: number; label: CapacityLabel }[]
> = {
  VAN: [
    {
      min: -100,
      max: -35,
      label: 'Very Loose Capacity',
    },
    {
      min: -35,
      max: -30,
      label: 'Slightly Loose Capacity',
    },
    {
      min: -30,
      max: -20,
      label: 'Moderately Loose Capacity',
    },
    {
      min: -20,
      max: 10,
      label: 'Neutral Market',
    },
    {
      min: 10,
      max: 35,
      label: 'Slightly Tight Capacity',
    },
    {
      min: 35,
      max: 45,
      label: 'Moderately Tight Capacity',
    },
    {
      min: 45,
      max: 100,
      label: 'Very Tight Capacity',
    },
  ],
  FLATBED: [
    {
      min: -100,
      max: -36,
      label: 'Very Loose Capacity',
    },
    {
      min: -36,
      max: -25,
      label: 'Slightly Loose Capacity',
    },
    {
      min: -25,
      max: -15,
      label: 'Moderately Loose Capacity',
    },
    {
      min: -15,
      max: 35,
      label: 'Neutral Market',
    },
    {
      min: 35,
      max: 60,
      label: 'Slightly Tight Capacity',
    },
    {
      min: 60,
      max: 94,
      label: 'Moderately Tight Capacity',
    },
    {
      min: 94,
      max: 100,
      label: 'Very Tight Capacity',
    },
  ],
};

/**
 * Determines the capacity label based on equipment type and MCI score
 *
 * @param params - Object containing equipmentType and mciScore
 * @returns The appropriate capacity label
 */
export const getCapacityLabel = ({
  equipmentType,
  mciScore,
}: {
  equipmentType?: number;
  mciScore?: number;
}): CapacityLabel | 'Unknown Capacity' => {
  if (!equipmentType || !mciScore) {
    return 'Unknown Capacity';
  }
  const boundedScore = Math.max(-100, Math.min(100, mciScore));
  const getEquipmentConfig = () => {
    if (equipmentType === 1) {
      return MCI_SCORE_CONFIG['FLATBED'];
    }
    if (equipmentType === 2) {
      return MCI_SCORE_CONFIG['VAN'];
    }
    console.log('=-= N/A');
    return null;
  };

  const equipmentConfig = getEquipmentConfig();

  if (!equipmentConfig) {
    return 'Unknown Capacity';
  }

  const matchingRange = equipmentConfig.find(
    (range) => boundedScore >= range.min && boundedScore <= range.max
  );

  return matchingRange?.label || 'Unknown Capacity';
};
