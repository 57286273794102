import Radio, { RadioProps } from '@mui/material/Radio';
import { withStyles } from 'tss-react/mui';

const CustomRadio = withStyles(
  (props: RadioProps) => <Radio color="default" {...props} />,
  {
    root: {
      '&.Mui-checked': {
        color: '#2D6CCB',
        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
          color: '#B9BDC1',
        },
      },
    },
    checked: {},
  }
);

export default CustomRadio;
