import axios from 'axios';
import { useMutation } from 'react-query';
import { getApiRoot } from 'utils/apiUtils';
import { useAuthorizationToken } from '@reibus/frontend-utility';

type Location = {
  city: string;
  state: string;
  zipCode: string;
};

export type RatesRequest = {
  origin: Location;
  destination: Location;
  equipmentType: number;
  pickupDate?: string;
};

export type RateSource = 'DAT' | 'REIBUS' | 'GREENSCREENS';

export type TimeFrame = '15_DAYS' | '30_DAYS';

export type CurrentRate = {
  rate: number;
  lowRate: number;
  highRate: number;
  source: RateSource;
  timeFrame: TimeFrame;
};

export type CurrentRatesResponse = {
  currentRates: CurrentRate[];
  distance: number;
  freightQuoteRequestId: string;
};

const getCurrentRates = async ({
  payload,
  authorization,
}: {
  payload: RatesRequest;
  authorization: string;
}) => {
  const url = `${getApiRoot()}/freight-quotes/quote-manager/current-rates`;
  const body = JSON.stringify(payload);
  const config = {
    headers: {
      Authorization: authorization,
    },
  };

  const { data } = await axios.post<CurrentRatesResponse>(url, body, config);

  return data;
};

const useGetCurrentRates = () => {
  const authorization = useAuthorizationToken();
  return useMutation<CurrentRatesResponse, Error, RatesRequest>((payload) =>
    getCurrentRates({ payload, authorization })
  );
};

export default useGetCurrentRates;
