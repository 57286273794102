import { makeStyles } from '@mui/styles';
import { Box, Theme, Typography } from '@mui/material';
import { AlgoliaShipmentProps } from 'api/shipment-visibility-dashboard';
import { PublicShipmentRespProps } from 'api/shared-shipment';
import {
  valuesFormatList,
  displayFormattingValue,
} from '@reibus/reibus-core-utils';
import { formattingOptions } from '@reibus/reibus-core-utils/dist/src/types/util';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { H3 } from 'components/SteelUI/atoms/typography';
type DatabaseIndexes =
  | 'shipmentID'
  | 'referenceNumber'
  | 'shipmentType'
  | 'commodity'
  | 'weight'
  | 'skidsAndPallets'
  | 'customId'
  | 'commodityType'
  | 'numberOfCoils'
  | 'apxWeightPerCoil'
  | 'quantity'
  | 'apxSkidCount'
  | 'outsideDiameter'
  | 'apxPieces';
const {
  listings: { weight: commaSeparated },
} = valuesFormatList;
const shipmentDetailsData: {
  databaseValue: DatabaseIndexes;
  label: string;
  modifier?(val: string, options: formattingOptions): string;
  modifier?(val: number, options: formattingOptions): string | number;
}[] = [
  {
    databaseValue: 'shipmentID',
    label: 'Reibus Logistics ID',
  },
  {
    databaseValue: 'customId',
    label: 'Reibus Logistics ID',
  }, // delete me after shared shipment page fetches from algolia
  {
    databaseValue: 'referenceNumber',
    label: '',
  },
  {
    databaseValue: 'shipmentType',
    label: 'Shipment Type',
  },
  {
    databaseValue: 'commodity',
    label: 'Commodity',
  },
  {
    databaseValue: 'weight',
    label: 'Weight (lbs)',
    modifier: (val, options) => displayFormattingValue(Number(val), options),
  },
  {
    databaseValue: 'skidsAndPallets',
    label: 'Skids/Pallets',
  },
  {
    databaseValue: 'commodityType',
    label: 'Commodity Type',
    modifier: (val) =>
      String(val)
        .split('_')
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' '),
  },
  {
    databaseValue: 'numberOfCoils',
    label: 'Number Of Coils',
    modifier: (val, options) => displayFormattingValue(Number(val), options),
  },
  {
    databaseValue: 'apxWeightPerCoil',
    label: 'Approx Weight Per Coil',
    modifier: (val, options) => displayFormattingValue(Number(val), options),
  },
  {
    databaseValue: 'quantity',
    label: 'Quantity',
    modifier: (val, options) => displayFormattingValue(Number(val), options),
  },
  {
    databaseValue: 'apxSkidCount',
    label: 'Approx Skid Count',
    modifier: (val, options) => displayFormattingValue(Number(val), options),
  },
  {
    databaseValue: 'outsideDiameter',
    label: 'Outside Diameter',
    modifier: (val, options) => displayFormattingValue(Number(val), options),
  },
  {
    databaseValue: 'apxPieces',
    label: 'Approx Pieces',
    modifier: (val, options) => displayFormattingValue(Number(val), options),
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  detailsTitle: {
    color: '#090A0D',
    marginBottom: theme.spacing(2),
  },
  detailsList: {
    padding: `0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
    backgroundColor: '#f5f5f5',
    color: theme.palette.info.main,
    borderRadius: 4,
    maxHeight: '255px',
    overflowY: 'auto',
  },
  shipmentDetail: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '14px',
  },
  shipmentDetailLabel: {
    fontWeight: theme.typography.fontWeightBold as number,
  },
  [theme.breakpoints.down('lg')]: {
    detailsTitle: {
      textAlign: 'left',
    },
  },
  [theme.breakpoints.down(900)]: {
    detailsList: {
      overflowY: 'none',
      maxHeight: '100%',
    },
  },
}));
type ShipmentItemProps = {
  shipmentDetails: AlgoliaShipmentProps | PublicShipmentRespProps;
};

const ShipmentItem = ({ shipmentDetails }: ShipmentItemProps) => {
  const classes = useStyles();
  const { ct390ModifyLoadSectionInFreightQuoteTool } = useFlags();
  shipmentDetails.referenceNumbers?.sort((a, b) => {
    if (a.label > b.label) {
      return 1;
    }
    if (a.label === b.label) {
      if (a.value > b.value) {
        return 1;
      }
      return -1;
    }
    return -1;
  });

  return (
    <>
      <Box paddingBottom="1rem">
        <H3 weight="bold">Shipment Details</H3>
      </Box>
      <div className={classes.detailsList}>
        {shipmentDetailsData
          .filter((detailData) => {
            if (ct390ModifyLoadSectionInFreightQuoteTool) {
              return detailData.databaseValue !== 'commodity';
            } else {
              return detailData.databaseValue !== 'commodityType';
            }
          })
          .map((detailData) => {
            const { label, databaseValue, modifier } = detailData;

            if (databaseValue === 'referenceNumber') {
              return shipmentDetails.referenceNumbers?.map(
                (referenceNumber, i) => {
                  return (
                    <div key={referenceNumber.value + i}>
                      <Typography
                        variant="body2"
                        className={classes.shipmentDetail}
                      >
                        <span className={classes.shipmentDetailLabel}>
                          {referenceNumber.label}:{' '}
                        </span>
                        <span>{referenceNumber.value}</span>
                      </Typography>
                    </div>
                  );
                }
              );
            }
            const rawValue = shipmentDetails[`${databaseValue}`];

            const value = modifier
              ? modifier(rawValue, commaSeparated)
              : rawValue;
            const hasValidValue =
              rawValue !== undefined &&
              value &&
              value !== 'N/A' &&
              value !== '0';

            return hasValidValue ? (
              <div key={databaseValue}>
                <Typography variant="body2" className={classes.shipmentDetail}>
                  <span className={classes.shipmentDetailLabel}>{label}: </span>
                  <span>{value}</span>
                </Typography>
              </div>
            ) : null;
          })}
      </div>
    </>
  );
};
export default ShipmentItem;
