import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, Box, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SupportingText from '../atoms/SupportingText';

type Props = {
  label: string;
  options: {
    label: string;
    value: string;
  }[];
  initialValue?: string;
  onChange: (value: string | undefined) => void;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  required?: boolean;
  inputValue?: string;
  onInputChange: (value: any) => void;
  error?: string;
  supportingText?: string;
};

const searchIcon = icon({ name: 'magnifying-glass', style: 'light' });
const clearIcon = icon({ name: 'xmark', style: 'light' });
const separatorIcon = icon({ name: 'pipe', style: 'light' });

const useStyles = makeStyles()((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      fontSize: '16px',
      borderRadius: '0.5rem',
      height: '2.625rem',
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '0.5rem',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#2D6CCB',
        borderWidth: '2px',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#2D6CCB',
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
        borderWidth: '2px',
      },
      '&.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
      },
      '& .MuiAutocomplete-popupIndicator': {
        transform: 'none',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '& .MuiAutocomplete-clearIndicator': {
        visibility: 'visible',
        padding: '0',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '& .MuiIconButton-root:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  label: {
    fontSize: '16px',
    display: 'flex',
    flexDirection: 'row',
    '&.MuiInputLabel-root:hover': {
      color: '#2D6CCB',
    },
    '&.Mui-focused': {
      color: '#2D6CCB',
    },
    '&.MuiFormLabel-filled': {
      color: '#2D6CCB',
    },
    '&.Mui-error': {
      color: theme.palette.error.main,
    },
    '& .MuiFormLabel-asterisk': {
      order: -1,
      margin: '0 4px 0 0',
    },
  },
  paper: {
    maxHeight: '10.6rem',
    borderRadius: '0.5rem',
    boxShadow: 'none',
    filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))',
  },
  listbox: {
    padding: 0,
    '& .MuiAutocomplete-option': {
      fontSize: '16px',
      height: '2.625rem',
      minHeight: '2.625rem',
      color: '#64676B',
      '&.Mui-selected': {
        backgroundColor: '#FFF',
      },
      '&:focus': {
        backgroundColor: '#FFF',
        border: '3px solid #21519933',
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  searchIcon: {
    color: '#64676B',
    fontSize: '14px',
    paddingRight: '0.5rem',
    '.MuiOutlinedInput-root:hover &': {
      color: '#2D6CCB',
    },
    '.MuiOutlinedInput-root.Mui-focused &': {
      color: '#2D6CCB',
    },
  },
  clearIcon: {
    color: '#64676B',
    fontSize: '14px',
    paddingRight: '0.5rem',
  },
  separator: {
    color: '#212224',
    fontSize: '14px',
    paddingLeft: '0.25rem',
    paddingRight: '0.75rem',
  },
}));

const SearchInputField = ({
  label,
  initialValue,
  options,
  onChange,
  onBlur,
  required,
  inputValue,
  onInputChange,
  error,
  supportingText,
}: Props) => {
  const { classes, cx } = useStyles();

  const handleChange = (
    _: React.SyntheticEvent,
    value: string | { label: string; value: string } | null
  ) => {
    if (!value) {
      onChange(undefined);
    } else if (typeof value === 'string') {
      onChange(value);
    } else {
      onChange(value.value);
    }
  };

  const handleInputChange = (
    _: React.SyntheticEvent,
    newInputValue: string
  ) => {
    onInputChange(newInputValue);
  };

  return (
    <>
      <Autocomplete
        options={options}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.label
        }
        onChange={handleChange}
        onBlur={onBlur}
        onInputChange={handleInputChange}
        value={initialValue || null}
        inputValue={inputValue || ''}
        size="small"
        className={cx(classes.root)}
        classes={{
          paper: classes.paper,
          listbox: classes.listbox,
        }}
        forcePopupIcon
        freeSolo
        clearOnBlur={false}
        disablePortal
        disableClearable={false}
        popupIcon={
          <FontAwesomeIcon
            icon={searchIcon}
            className={cx(classes.searchIcon)}
          />
        }
        clearIcon={
          <>
            <FontAwesomeIcon
              icon={clearIcon}
              className={cx(classes.clearIcon)}
            />
            <FontAwesomeIcon
              icon={separatorIcon}
              className={cx(classes.separator)}
            />
          </>
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            InputLabelProps={{
              className: classes.label,
            }}
            required={required}
            error={!!error}
          />
        )}
        fullWidth
      />

      <Box paddingTop="8px">
        <SupportingText
          error={error}
          required={required}
          text={supportingText}
        />
      </Box>
    </>
  );
};

export default SearchInputField;
