import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ClickAwayListener,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material';
import Button from 'components/SteelUI/atoms/Button';
import Checkbox from 'components/SteelUI/atoms/Checkbox';
import { Body } from 'components/SteelUI/atoms/typography';
import { useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  menu: {
    '& .MuiMenu-list': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    borderRadius: '4px',
  },
  menuItem: {
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      border: '1px solid transparent',
    },
  },
  searchMenuItem: {
    width: '100%',
    padding: 0,
    borderBottom: '1px solid #EDEEF0',
  },
  searchBox: {
    width: '100%',
    '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .Mui-focused': {
      backgroundColor: '#FFF',
      border: 'none',
    },
    '&:hover': {
      border: 'none',
    },
  },
  searchIcon: {
    color: '#64676B',
    height: '1rem',
    width: '1rem',
  },
  input: {
    color: '#64676B',
    height: '2.75rem',
    padding: 0,
    '&::placeholder': {
      opacity: 1,
    },
  },
  selectAll: {
    borderTop: '1px solid #EDEEF0',
    borderBottom: '1px solid #EDEEF0',
  },
  indentedOption: {
    paddingLeft: '16px',
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
  },
  menuPaper: {
    marginTop: '4px',
    boxShadow: '0px 0px 4px 0px rgba(33, 34, 36, 0.10)',
  },
}));

type Props = {
  variant?: 'outlined' | 'contained';
  label: string;
  value: string[];
  options: {
    label: string;
    value: string;
  }[];
  onSelect: (value: string[]) => void;
};
const ButtonDropdown = ({
  variant,
  label,
  value,
  options,
  onSelect,
}: Props) => {
  const { classes, cx } = useStyles();
  const firstOptionRef = useRef<HTMLLIElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [visibleOptions, setVisibleOptions] = useState(options);
  const [search, setSearch] = useState('');

  const handleSearch = (text: string) => {
    setSearch(text);
    const newOptions = options.filter(
      (option) =>
        option.label.toLowerCase().includes(text.toLowerCase()) ||
        value.includes(option.value)
    );
    setVisibleOptions(newOptions);
  };

  const handleClearSearch = () => {
    setSearch('');
    setVisibleOptions(options);
  };

  const chevronIcon = open
    ? icon({ name: 'chevron-up', style: 'light' })
    : icon({ name: 'chevron-down', style: 'light' });

  const searchIcon = icon({ name: 'search', style: 'light' });

  const handleClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleSelectAll = () => {
    if (value.length) {
      onSelect([]);
      return;
    }
    const allValues = options.map((option) => option.value);
    onSelect(allValues);
  };

  const handleSelectOne = (selectedValue: string) => {
    if (value.includes(selectedValue)) {
      onSelect(value.filter((v) => v !== selectedValue));
      return;
    }
    onSelect([...value, selectedValue]);
  };

  const count = !value.length ? '' : ` (${value.length})`;

  useEffect(() => {
    setVisibleOptions(options);
  }, [options]);

  return (
    <div>
      <Button
        variant={variant ?? 'contained'}
        onClick={handleClickButton}
        endIcon={
          <div>
            <FontAwesomeIcon icon={chevronIcon} size="2xs" />
          </div>
        }
      >
        {label}
        {count}
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={() => setOpen(false)}
        className={classes.menu}
        PaperProps={{ className: classes.menuPaper }}
      >
        <ClickAwayListener
          onClickAway={() => {
            if (!handleClearSearch) {
              return;
            }
            handleClearSearch();
          }}
        >
          <MenuItem className={cx(classes.menuItem, classes.searchMenuItem)}>
            <TextField
              type="search"
              placeholder="Search"
              className={classes.searchBox}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      className={classes.searchIcon}
                      icon={searchIcon}
                    />
                  </InputAdornment>
                ),
                classes: { input: classes.input },
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === 'ArrowDown') {
                  firstOptionRef?.current?.focus();
                }
              }}
              onChange={(e) => handleSearch(e.target.value)}
              value={search}
            />
          </MenuItem>
        </ClickAwayListener>
        {search ? null : (
          <MenuItem
            onClick={handleSelectAll}
            className={cx(
              value.length > 0 && classes.selected,
              classes.menuItem,
              classes.selectAll
            )}
          >
            <Checkbox
              checked={value.length > 0 && value.length === options.length}
              indeterminate={value.length > 0 && value.length < options.length}
            />
            <Body weight={value.length > 0 ? 'bold' : 'regular'}>
              Select All
            </Body>
          </MenuItem>
        )}
        {visibleOptions.map((option) => {
          const selected = value.includes(option.value);
          return (
            <MenuItem
              key={`button-dropdown-option-${option.value}`}
              value={option.value}
              onClick={() => handleSelectOne(option.value)}
              className={cx(classes.menuItem, selected && classes.selected)}
            >
              <Checkbox checked={selected} className={classes.indentedOption} />
              <Body weight={selected ? 'bold' : 'regular'}>{option.label}</Body>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default ButtonDropdown;
