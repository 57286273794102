import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  TypographyProps,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type Props = CheckboxProps;

type CheckboxWithLabelProps = {
  label: string;
  typographyProps?: TypographyProps;
} & Props;

const useCheckboxIconStyles = makeStyles()(() => ({
  checkbox: {
    width: 16,
    height: 16,
  },
  label: {
    marginBottom: '-2px',
    color: '#212224',
  },
  labelDisabled: {
    marginBottom: '-2px',
    color: '#B9BDC1',
  },
}));

const uncheckedIcon = icon({ name: 'square', style: 'light' });
const checkedIcon = icon({ name: 'check-square', style: 'solid' });
const indeterminateIcon = icon({ name: 'minus-square', style: 'solid' });

const Checkbox = ({ ...rest }: Props) => {
  return (
    <MuiCheckbox
      {...rest}
      icon={<FontAwesomeIcon icon={uncheckedIcon} color="#2D6CCB" />}
      checkedIcon={<FontAwesomeIcon icon={checkedIcon} />}
      indeterminateIcon={<FontAwesomeIcon icon={indeterminateIcon} />}
      sx={{ paddingLeft: 0 }}
    />
  );
};

export const CheckboxWithLabel = ({
  label,
  typographyProps = { variant: 'body1' },
  checked,
  onChange,
  ...checkboxProps
}: CheckboxWithLabelProps) => {
  const { classes } = useCheckboxIconStyles();
  return (
    <FormControlLabel
      label={
        <Typography
          {...typographyProps}
          className={
            checkboxProps.disabled ? classes.labelDisabled : classes.label
          }
          onClick={(e) => e.stopPropagation()}
        >
          {label}
        </Typography>
      }
      control={
        <MuiCheckbox
          {...checkboxProps}
          disableRipple
          checked={checked}
          onChange={onChange}
          icon={<FontAwesomeIcon icon={uncheckedIcon} color="#2D6CCB" />}
          checkedIcon={<FontAwesomeIcon icon={checkedIcon} />}
          indeterminateIcon={<FontAwesomeIcon icon={indeterminateIcon} />}
          sx={{
            '&:hover': { bgcolor: 'transparent' },
            paddingRight: '8px',
            '&.Mui-checked': {
              color: '#2D6CCB',
            },
          }}
        />
      }
    />
  );
};

export default Checkbox;
