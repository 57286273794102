import { newTheme } from 'theme';
import QuickFreightQuoteV1 from './QuickFreightQuote';
import QuickFreightQuoteV2 from './v2/QuickFreightQuote';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ThemeProvider } from '@mui/material/styles';

const QuickFreightQuote = () => {
  const { ct713UpdateFreightQuoteStyles = false } = useFlags();

  return ct713UpdateFreightQuoteStyles ? (
    <ThemeProvider theme={newTheme}>
      <QuickFreightQuoteV2 />
    </ThemeProvider>
  ) : (
    <QuickFreightQuoteV1 />
  );
};

export default QuickFreightQuote;
