import PageWrapper from 'components/PageWrapper';
import { makeStyles } from '@mui/styles';
import { usePageView } from 'utils/mixpanel';
import { H3, Title } from 'components/SteelUI/atoms/typography';

import { useState, useEffect } from 'react';
import { FreightQuoteRequest } from 'api/request-for-quote';
import { useLocation } from 'react-router-dom';

import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import QuickFreightQuoteForm from 'quick-freight-quote/v2/components/QuickFreightQuoteForm';

type AutoCompletionRequest = google.maps.places.AutocompletionRequest;

const useStyles = makeStyles({
  root: {
    backgroundColor: '#F6F7F7',
    minHeight: '100vh',
  },
  header: {
    backgroundColor: '#FFFFFF',
    padding: '24px 32px',
  },
  formWrapper: {
    padding: '32px 24px',
  },
});

const DEFAULT_FORM_DATA: Record<string, any> = {
  originSystem: 'FREIGHT_QUOTE_UI',
  equipment: 'FLATBED',
};

const getPlacePredictionsFns = (types: string[]) =>
  useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    options: {
      types,
    } as AutoCompletionRequest,
  });

export type PlacePredictionFn = (
  opt: google.maps.places.AutocompletionRequest
) => void;

const QuickFreightQuote = () => {
  const classes = useStyles();
  usePageView(`Page View: Quick Freight Quote`);

  const { state }: { state: FreightQuoteRequest } = useLocation();

  const [initialFormData, setInitialFormData] = useState<Record<string, any>>(
    {}
  );
  const [isLoading, setIsLoading] = useState(true);

  let placesServiceInstance: google.maps.places.PlacesService | null = null;

  ['(regions)', 'address'].forEach((searchType) => {
    const { placesService = null } = getPlacePredictionsFns([searchType]);
    if (!placesServiceInstance) {
      placesServiceInstance = placesService;
    }
  });

  useEffect(() => {
    if (state && placesServiceInstance) {
      setInitialFormData({
        ...DEFAULT_FORM_DATA,
        ...state,
      });
      setIsLoading(false);
    } else {
      setInitialFormData(DEFAULT_FORM_DATA);
      setIsLoading(false);
    }
  }, [placesServiceInstance]);

  return (
    <PageWrapper showFooter={false}>
      <div className={classes.root}>
        <div className={classes.header}>
          <Title weight="bold">Quick Freight Quote</Title>
          <H3 textTransform="capitalize">
            Enter shipment details to get a quick quote
          </H3>
        </div>
        <div className={classes.formWrapper}>
          {!isLoading && initialFormData.originSystem ? (
            <QuickFreightQuoteForm
              isLoading={isLoading}
              placesServiceInstance={placesServiceInstance}
              initialFormData={initialFormData}
            />
          ) : null}
        </div>
      </div>
    </PageWrapper>
  );
};

export default QuickFreightQuote;
