import { MenuItem, MenuItemProps } from '@mui/material';
import { CheckboxWithLabel } from 'components/SteelUI/atoms/Checkbox';
import { makeStyles } from 'tss-react/mui';

type Props = {
  value: string;
  label: string;
  isSelected: boolean;
  indeterminate?: boolean;
  selectedCount?: number;
  checkbox?: boolean;
} & MenuItemProps;

const useStyles = makeStyles()(({ palette }) => ({
  menuItem: {
    height: '2.625rem',
    minHeight: '2.625rem',
    color: '#64676B',
    '&.Mui-selected': {
      backgroundColor: '#FFF',
    },
    '&:focus': {
      backgroundColor: '#FFF !important',
      border: '3px solid #21519933',
    },
    '&:hover': {
      backgroundColor: `${palette.secondary.main} !important`,
    },
  },
  menuItemSelected: {
    height: '2.625rem',
    minHeight: '2.625rem',
    backgroundColor: `${palette.secondary.main} !important`,
    fontWeight: 700,
    color: '#212224',
  },
}));

const GroupDropdownOption = ({
  value,
  label,
  isSelected,
  indeterminate,
  selectedCount = 0,
  checkbox,
  ...rest
}: Props) => {
  const { classes } = useStyles();

  const formattedLabel =
    selectedCount > 0 ? `${label} (${selectedCount})` : label;

  return (
    <MenuItem
      value={value}
      className={isSelected ? classes.menuItemSelected : classes.menuItem}
      sx={{ whiteSpace: 'normal' }}
      {...rest}
    >
      {!checkbox ? (
        label
      ) : (
        <CheckboxWithLabel
          checked={isSelected}
          indeterminate={indeterminate}
          label={formattedLabel}
          typographyProps={{ fontWeight: '700' }}
        />
      )}
    </MenuItem>
  );
};

export default GroupDropdownOption;
