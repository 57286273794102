import { useUserSession } from '@reibus/frontend-utility';

import FormSection from './FormSection';
import { PhoneInput } from 'components/library/form/PhoneField';
import TextField from './TextField';
import {
  emailValidator,
  phoneValidator,
} from 'components/library/form/fieldValidators';
import { Stack } from '@mui/material';

const ContactInformation = () => {
  const { data: userSession = {} } = useUserSession();
  const { isReibusUser = false } = userSession;

  return (
    <FormSection title="Contact Information">
      <Stack direction="row" spacing={3}>
        <TextField label="Contact Name" name="contactName" required />
        <TextField
          label="Contact Phone"
          name="contactPhone"
          required
          validator={phoneValidator}
          InputProps={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: PhoneInput as any,
          }}
        />
        <TextField
          label="Contact Email"
          name="contactEmail"
          required
          validator={emailValidator}
        />
        {isReibusUser ? (
          <TextField label="Deal ID" name="dealID" required />
        ) : null}
      </Stack>
    </FormSection>
  );
};

export default ContactInformation;
