import {
  Theme,
  Container,
  Typography,
  SvgIcon,
  useMediaQuery,
  Button,
  Stack,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FreightQuoteRequest } from 'api/request-for-quote';
import { useHistory, useLocation } from 'react-router-dom';
import { RouteArrow } from 'components/icons/RouteArrow';
import clsx from 'clsx';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { trackEvent } from 'utils/mixpanel';

import {
  faLocationCrosshairs,
  faLocationDot,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Body } from 'components/SteelUI/atoms/typography';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    justifyContent: 'center',
    width: 'auto',
    padding: 0,
  },
  locationWrapper: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontSize: 20,
    marginBottom: 0,
  },
  locationContainer: {
    padding: 0,
    display: 'flex',
  },
  locationLabels: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
  },
  address: {
    marginTop: theme.spacing(1),
  },
  arrow: {
    height: 80,
    marginTop: '-45px',
    marginLeft: 4,
  },
  destinationIcon: {
    marginLeft: 3,
  },
  containerTablet: {
    width: '50%',
  },
  button: {
    color: '#2D6CCB',
    border: '2px solid #2D6CCB',
    borderRadius: '8px',
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 700,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      border: '2px solid transparent',
      boxShadow: '0px 1px 3px 1px #21222426',
    },
  },
}));

type address = {
  address1: string;
  city: string;
  region: string;
  postalCode: string;
  country: string;
};

type props = {
  originAddress: address;
  destinationAddress: address;
};

type containerProps = {
  subtitle: string;
  icon: JSX.Element;
  address: string;
};

const LocationsContainer = ({ subtitle, icon, address }: containerProps) => {
  const classes = useStyles();
  return (
    <>
      <Container className={classes.locationContainer}>
        {icon}
        <Typography className={classes.locationLabels}>
          <Body size="small" weight="medium" color="#2D6CCB">
            {subtitle}
          </Body>
          <Body color="#64676B" className={classes.address}>
            {address}
          </Body>
        </Typography>
      </Container>
    </>
  );
};

export const LocationRoute = ({ originAddress, destinationAddress }: props) => {
  const classes = useStyles();

  return (
    <>
      <LocationsContainer
        address={`${originAddress.city}, ${originAddress.region}, ${originAddress.country}`}
        icon={
          <FontAwesomeIcon
            icon={faLocationCrosshairs}
            fontSize="24px"
            color="#2D6CCB"
          />
        }
        subtitle={'ORIGIN'}
      />
      <SvgIcon className={classes.arrow}>
        <RouteArrow />
      </SvgIcon>
      <LocationsContainer
        address={`${destinationAddress.city}, ${destinationAddress.region}, ${destinationAddress.country}`}
        icon={
          <FontAwesomeIcon
            icon={faLocationDot}
            fontSize="24px"
            color="#2D6CCB"
            className={classes.destinationIcon}
          />
        }
        subtitle={'DESTINATION'}
      />
    </>
  );
};

const Location = ({ originAddress, destinationAddress }: props) => {
  const classes = useStyles();
  const tablet = useMediaQuery('(min-width:500px) and (max-width:900px)');
  const tabletClass = tablet ? classes.containerTablet : null;
  const { state: quoteToCopy }: { state: FreightQuoteRequest } = useLocation();
  const history = useHistory();
  const { ct48EditButtonOnFreightQuoteDetailsPage } = useFlags();

  const handleEdit = () => {
    const { id, refID, ...state } = quoteToCopy;

    if (quoteToCopy.quickForm) {
      // if we push origin and destination address to the state, our quote functions will use them by default.
      // let's remove them from state before returning to the form
      const { originAddress, destinationAddress, ...rest } = state;

      trackEvent('Edit Quick Quote Clicked');
      history.push('/quick-freight-quote', {
        ...rest,
        equipment: state.equipment[0],
      });
    } else {
      trackEvent('Edit Freight Quote Clicked', { id });
      history.push('/request-for-quote', { ...state });
    }
  };

  return (
    <Container className={clsx(classes.container, tabletClass)}>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.locationWrapper}
      >
        <Typography className={classes.title} variant="h5">
          Locations
        </Typography>
        {ct48EditButtonOnFreightQuoteDetailsPage ? (
          <Button
            variant="outlined"
            className={classes.button}
            onClick={handleEdit}
          >
            Edit
          </Button>
        ) : null}
      </Stack>

      <LocationRoute
        originAddress={originAddress}
        destinationAddress={destinationAddress}
      />
    </Container>
  );
};

export default Location;
