import { makeStyles } from '@mui/styles';
import { Typography, Theme, Divider, useMediaQuery } from '@mui/material';
import { dateDisplayNoMidnight, formatDateDisplay } from '../ShipmentUtils';
import ShipmentProgress from '../ShipmentProgress';
import { Status, StringKey, TurvoGlobalRouteAttributesDate } from 'api/types';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#939393',
  },
  colorTitle: {
    fontSize: '14px',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#939393',
  },
  infoLine: {
    fontSize: '14px',
    color: '#939393',
  },
  divider: {
    color: 'black',
    marginTop: theme.spacing(3),
  },
}));

type ShipmentStatusItemType = {
  title: string;
  location: string;
  schedule?: TurvoGlobalRouteAttributesDate;
  arrival?: TurvoGlobalRouteAttributesDate;
  departure?: TurvoGlobalRouteAttributesDate;
  info?: string;
  isStarted?: boolean;
  isDelivered?: boolean;
  progressStatus: Status;
  hasDeliveryNext?: boolean;
  lastDelivery?: boolean;
  schedulingType?: StringKey;
  shipmentStatusCode: string;
  flex?: number;
};

const deliveryType = 'Delivery';

const ShipmentStatusItem = (item: ShipmentStatusItemType) => {
  const classes = useStyles();
  const mobile = useMediaQuery('(min-width: 500px) and (max-width:1175px)');

  const isColoredTitle =
    item.isStarted ||
    item.arrival ||
    item.departure ||
    (item.isDelivered && item.info) ||
    (item.isDelivered && item.progressStatus?.status === 'delivered');

  return (
    <ShipmentProgress
      progressStatus={item.progressStatus}
      progressStatusName={item.title.toLowerCase()}
      arrival={item.arrival || item.departure}
      hasDeliveryNext={item.hasDeliveryNext}
      lastDelivery={item.lastDelivery}
    >
      <>
        <Typography
          className={isColoredTitle ? classes.colorTitle : classes.title}
        >
          {item.title.toUpperCase()}
        </Typography>
        <Typography className={classes.subtitle}>{item.location}</Typography>
        {item.schedule ? (
          <Typography className={classes.infoLine}>
            Scheduled:{' '}
            {formatDateDisplay(
              item.schedule.date,
              item.schedule.timezone,
              item.flex,
              item.shipmentStatusCode,
              item.schedulingType?.key
            )}
          </Typography>
        ) : null}
        {item.arrival ? (
          <Typography className={classes.infoLine}>
            Arrived:{' '}
            {dateDisplayNoMidnight(item.arrival.date, item.arrival.timezone)}
          </Typography>
        ) : null}
        {item.departure && item.title != deliveryType ? (
          <Typography className={classes.infoLine}>
            Departed:{' '}
            {dateDisplayNoMidnight(
              item.departure.date,
              item.departure.timezone
            )}
          </Typography>
        ) : null}
        {item.info ? (
          <Typography className={classes.infoLine}>{item.info}</Typography>
        ) : null}
        {item.title.toLowerCase() === 'delivered' && mobile ? (
          <Divider className={classes.divider} />
        ) : null}
      </>
    </ShipmentProgress>
  );
};

export default ShipmentStatusItem;
