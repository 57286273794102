import { useState, ChangeEvent } from 'react';
import { makeStyles } from '@mui/styles';
import { FormControl, TextField, Autocomplete } from '@mui/material';
import { Field } from 'react-final-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormFieldError } from './FormFieldError';
import { SelectOption } from './types';
import { autoSelectValidator as defaultValidator } from './fieldValidators';

const useStyles = makeStyles(() => ({
  asterisk: { color: '#C62828', fontSize: '1.2rem' },
  focused: {},
  error: {},
  underline: {
    '&.Mui-error': {
      '&::after': {
        borderBottomColor: '#92140c',
      },
    },
  },
}));

type AutoSelectProps = {
  placeholder?: string;
  name: string;
  label: string;
  required?: boolean;
  options: SelectOption[];
  validator?: (value: string[]) => string | void;
  defaultValue?: string;
};

export const AutoSelect = ({
  options,
  placeholder,
  required = true,
  name,
  defaultValue,
  validator = defaultValidator,
}: AutoSelectProps) => {
  const classes = useStyles();
  const validate = required ? validator : undefined;
  const [value, setValue] = useState<SelectOption[] | undefined>();

  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        const defaultOption = input.value
          ? options.filter((op) => input.value?.includes(op.optionName))
          : options.filter((op) => op.optionName === defaultValue);

        const handleChange = (
          // eslint-disable-next-line @typescript-eslint/ban-types
          event: ChangeEvent<{}>,
          value: SelectOption[]
        ) => {
          const optionsSelected = value.map((el) => el.optionName);
          input.onChange(optionsSelected);
          setValue(value);
        };

        return (
          <FormControl fullWidth required={required} error={false}>
            <Autocomplete
              data-testid={`autocomplete-${name}`}
              getOptionLabel={(option) => option.label}
              options={options}
              defaultValue={defaultOption}
              onChange={handleChange}
              value={value ? value : defaultOption}
              popupIcon={<ExpandMoreIcon color="primary" />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputLabelProps={{
                    classes: { asterisk: classes.asterisk },
                  }}
                  label={placeholder}
                  required={required}
                  name={name}
                />
              )}
              onBlur={input.onBlur}
              onFocus={input.onFocus}
              multiple
            />
            <FormFieldError meta={meta} />
          </FormControl>
        );
      }}
    </Field>
  );
};
