import { makeStyles } from '@mui/styles';
import { Grid, Divider, Box, useMediaQuery, Theme } from '@mui/material';
import StatusItem from './StatusItem';
import DetailRoute from './DetailRoute';
import {
  ArrowRightAlt,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import { AlgoliaShipmentProps } from 'api/shipment-visibility-dashboard';
import theme from 'theme';
import ShipmentStatus from 'shipment-visibility-dashboard/ShipmentStatus';
import { formatDateDisplay } from '../../Shipment/ShipmentUtils';

export const COMPLETED_MARKETPLACE_LABEL = 'Marketplace';

const useStyles = makeStyles((theme: Theme) => ({
  arrowIcon: {
    alignSelf: 'center',
    justifyContent: 'center',
    color: '#C4C4C4',
  },
  accordionArrows: {
    alignSelf: 'center',
  },
  arrowRight: {
    width: '100%',
  },
  dividerContainer: {
    padding: `${theme.spacing(2)} 0`,
  },
  cursor: {
    cursor: 'pointer',
  },
  [theme.breakpoints.down(1300)]: {
    dividerContainer: {
      padding: `${theme.spacing(2)} 0 ${theme.spacing(2)} ${theme.spacing(
        1.5
      )}`,
    },
  },
  [theme.breakpoints.down('sm')]: {
    dividerContainer: {
      padding: `${theme.spacing(2)} ${theme.spacing(1.5)} `,
    },
  },
  divider: {
    marginRight: 16,
    marginLeft: 16,
  },
}));

type shipmentTypes = {
  data: AlgoliaShipmentProps;
  isSelected: boolean;
  hideShipment: (e: React.MouseEvent<HTMLElement>) => void;
};

const statusIsMoveCompleted = (statusCode: string): boolean =>
  ShipmentStatus.MoveCompletedStatusCodes.includes(statusCode);

export const getShipmentLabel = (
  statusCode: string,
  label: string,
  isMarketplaceShipment: boolean
) => {
  if (!isMarketplaceShipment) return label;

  return statusIsMoveCompleted(statusCode)
    ? COMPLETED_MARKETPLACE_LABEL
    : label;
};

const Shipment = ({ data, isSelected, hideShipment }: shipmentTypes) => {
  const {
    shipmentID,
    label,
    updatedAt,
    startLocationLabel,
    endLocationLabel,
    statusCode,
    isMarketplaceShipment,
    startDateFull,
    endDateFull,
  } = data;
  const classes = useStyles();
  const mobile = useMediaQuery('(max-width: 1175px)');

  const mobileOrNotMobileStyles = {
    height: mobile ? 100 : 89,
    marginTop: mobile ? theme.spacing(2) : theme.spacing(1),
    paddingTop: mobile ? 0 : '8px',
    paddingBottom: mobile ? 0 : '8px',
  };

  const statusLabel = getShipmentLabel(
    statusCode,
    label,
    isMarketplaceShipment
  );

  return (
    <Box
      sx={{
        width: '100%',
        border: `${
          isSelected
            ? `2px solid ${theme.palette.primary.main}`
            : '1px solid #EEEEEE'
        }`,
        borderRadius: '10px',
        ...mobileOrNotMobileStyles,
      }}
    >
      <Grid container>
        <Grid item xs={3}>
          <StatusItem
            shipmentId={shipmentID}
            status={statusLabel}
            dateTime={updatedAt}
            isSelected={isSelected}
          />
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ mr: '-1px' }}
          variant="middle"
          className={classes.divider}
        />
        <Grid item xs={3}>
          <DetailRoute
            isOriginAddress
            location={startLocationLabel}
            date={formatDateDisplay(
              startDateFull.date,
              startDateFull.timeZone,
              undefined,
              statusCode
            )}
          />
        </Grid>
        <Grid item xs={1} className={classes.arrowIcon}>
          <ArrowRightAlt className={classes.arrowRight} />
        </Grid>
        <Grid item xs={3}>
          <DetailRoute
            location={endLocationLabel}
            date={formatDateDisplay(
              endDateFull.date,
              endDateFull.timeZone,
              undefined,
              statusCode
            )}
          />
        </Grid>
        {mobile ? (
          <Grid item xs={1} className={classes.accordionArrows}>
            <div
              onClick={hideShipment}
              data-testid="accordionArrow"
              className={classes.cursor}
            >
              {isSelected ? (
                <KeyboardArrowUp htmlColor="#000000" />
              ) : (
                <KeyboardArrowDown htmlColor="#000000" />
              )}
            </div>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default Shipment;
