import Search from 'components/SteelUI/molecules/Search';
import { useEffect, useState } from 'react';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

type AutocompletePrediction = google.maps.places.AutocompletePrediction;
type AutoCompletionRequest = google.maps.places.AutocompletionRequest;
type GooglePlaceSearchType = '(cities)' | 'address' | '(regions)'; // NOTE: `(regions) enables search by zipcode`

const getUniquePlaces = (predictions: AutocompletePrediction[]) => {
  const uniquePlaceIds = [
    ...new Set(predictions.map(({ place_id }) => place_id)),
  ];
  return uniquePlaceIds.map((id) =>
    predictions.find(({ place_id }) => id === place_id)
  ) as AutocompletePrediction[];
};

export type Address = {
  address1?: string;
  address2?: string;
  postalCode?: string;
  city?: string;
  region?: string;
  country?: string;
};

type Props = {
  label: string;
  initialPlaceId?: string;
  onChange: (placeId: string | undefined) => void;
  onInputChange?: (value: string) => void;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  required?: boolean;
  searchTypes: GooglePlaceSearchType[];
  maxResults?: number;
  error?: string;
  supportingText?: string;
};

export const getAddressFromPlaceId = async (
  placeId: string
): Promise<Address> => {
  return new Promise((resolve, reject) => {
    const placesService = new google.maps.places.PlacesService(
      document.createElement('div')
    );

    placesService.getDetails(
      {
        placeId,
        fields: ['address_components', 'formatted_address'],
      },
      (place, status) => {
        if (status !== 'OK' || !place) {
          reject(new Error('Failed to fetch place details'));
          return;
        }

        const address: Address = {};

        place.address_components?.forEach((component) => {
          const types = component.types;

          if (types.includes('street_number') || types.includes('route')) {
            // Combine street number and route for address1
            address.address1 = address.address1
              ? `${address.address1} ${component.long_name}`
              : component.long_name;
          }
          if (types.includes('subpremise')) {
            address.address2 = component.long_name;
          }
          if (types.includes('locality')) {
            address.city = component.long_name;
          }
          if (types.includes('administrative_area_level_1')) {
            address.region = component.short_name;
          }
          if (types.includes('postal_code')) {
            address.postalCode = component.long_name;
          }
          if (types.includes('country')) {
            address.country = component.short_name;
          }
        });

        resolve(address);
      }
    );
  });
};

const LocationSearch = ({
  label,
  initialPlaceId,
  onChange,
  onInputChange,
  onBlur,
  required,
  searchTypes,
  maxResults = 6,
  error,
  supportingText,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>();
  const [selectedPlaceId, setSelectedPlaceId] = useState<string | undefined>(
    initialPlaceId
  );

  const searchResults = searchTypes.map((searchType) =>
    useGoogle({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
      options: {
        types: [searchType],
        componentRestrictions: {
          country: ['us'],
        },
      } as AutoCompletionRequest,
    })
  );

  const placeFetchers = searchResults.map(
    (result) => result.getPlacePredictions
  );
  const places = searchResults.flatMap((result) => result.placePredictions);
  const placesServiceInstance = searchResults[0]?.placesService ?? null;

  const fetchPlacePredictions = (term: AutoCompletionRequest) => {
    placeFetchers.forEach((placeFetcher) => placeFetcher(term));
  };

  const combinePredictions = () =>
    getUniquePlaces(places)
      .slice(0, maxResults)
      .map((places) => ({
        label: places.description,
        value: places.place_id,
      }));

  const handleInputChange = (value: string) => {
    setInputValue(value);

    if (value && value.trim()) {
      fetchPlacePredictions({ input: value });
    }

    if (onInputChange) {
      onInputChange(value);
    }
  };

  const handleChange = (value: string | undefined) => {
    if (value) {
      setSelectedPlaceId(value);
    } else {
      setSelectedPlaceId(undefined);
    }
    onChange(value);
  };

  useEffect(() => {
    if (!initialPlaceId || !placesServiceInstance) {
      return;
    }
    placesServiceInstance.getDetails(
      {
        placeId: initialPlaceId,
      },
      (placeDetails) => {
        if (!placeDetails) {
          return;
        }
        setInputValue(placeDetails.formatted_address);
        setSelectedPlaceId(initialPlaceId);
        if (!placeDetails.formatted_address) {
          return;
        }
        fetchPlacePredictions({
          input: placeDetails.formatted_address,
        });
      }
    );
  }, [placesServiceInstance, initialPlaceId, fetchPlacePredictions]);

  return (
    <Search
      label={label}
      initialValue={initialPlaceId}
      options={combinePredictions()}
      onChange={handleChange}
      onBlur={onBlur}
      required={required}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      error={error}
      supportingText={supportingText}
    />
  );
};

export default LocationSearch;
