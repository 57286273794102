import { ThemeProvider } from '@mui/material/styles';
import RequestForQuoteV2 from './v2/RequestForQuoteV2';
import RequestForQuoteV3 from './v3/RequestForQuote';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { newTheme } from 'theme';

const QuickFreightQuote = () => {
  const { ct713UpdateFreightQuoteStyles = false } = useFlags();

  return ct713UpdateFreightQuoteStyles ? (
    <ThemeProvider theme={newTheme}>
      <RequestForQuoteV3 />
    </ThemeProvider>
  ) : (
    <RequestForQuoteV2 />
  );
};

export default QuickFreightQuote;
