import { useRef } from 'react';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import Button from 'components/SteelUI/atoms/Button';
import { Body } from 'components/SteelUI/atoms/typography';

import Location from 'request-for-quote/v3/form/Location';
import { useState } from 'react';
import Loader from 'components/Loader';

import { useFreightEstimate } from 'hooks/useFreightEstimate';

import { trackEvent } from 'utils/mixpanel';

import { EquipmentSingleSelect } from 'request-for-quote/v3/form/Equipment';
import { PickUpDateInput } from 'request-for-quote/v3/form/Date';
import FormSection from 'request-for-quote/v3/form/FormSection';
import NoQuoteModal from 'request-for-quote/v3/form/NoQuoteModal';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    boxShadow: '0px 0px 4px 0px rgba(33, 34, 36, 0.10)',
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  },
  button: {
    padding: '12px 32px',
    lineHeight: '125%',
  },
  link: {
    color: '#2D6CCB',
    textDecoration: 'none',
  },
  // TODO: When navigating from marketplace, we still have new-rfq-form.css loaded which affects the form width
  form: { width: '100%', maxWidth: '100%' },
});

type QuickFreightQuoteFormProps = {
  isLoading: boolean;
  initialFormData: Record<string, any>;
  placesServiceInstance: google.maps.places.PlacesService | null;
};

const QuickFreightQuoteForm = ({
  isLoading,
  initialFormData,
  placesServiceInstance,
}: QuickFreightQuoteFormProps) => {
  const classes = useStyles();

  const history = useHistory();

  const [noQuoteModalOpen, setNoQuoteModalOpen] = useState(false);

  const submittedValuesRef = useRef<Record<string, any> | null>(null);

  const { mutate: getFreightEstimate, isLoading: isSubmitting } =
    useFreightEstimate({
      onSuccess: (freightEstimate) => {
        trackEvent('Quick Freight Quote', {
          equipment: submittedValuesRef.current?.equipment,
          pickupDate: submittedValuesRef.current?.pickupDate,
          originAddress: freightEstimate.transformedOriginAddress,
          destinationAddress: freightEstimate.transformedDestinationAddress,
          estimate: freightEstimate.estimate,
          confidenceLevel: freightEstimate.confidenceLevel,
        });

        if (freightEstimate.estimate && submittedValuesRef.current?.equipment) {
          const {
            originAddressBreakdown,
            destinationAddressBreakdown,
            ...rest
          } = submittedValuesRef.current;
          history.push({
            pathname: `/quote-details`,
            state: {
              quickForm: true,
              ...rest,
              equipment: [submittedValuesRef.current.equipment],
              priceEstimate: freightEstimate.estimate,
              destinationAddress: {
                ...freightEstimate?.transformedDestinationAddress,
              },
              originAddress: {
                ...freightEstimate?.transformedOriginAddress,
              },
            },
          });
        } else {
          setNoQuoteModalOpen(true);
        }
      },
      onError: () => {
        setNoQuoteModalOpen(true);
      },
    });

  const handleSubmit = async (values: Record<string, any>) => {
    submittedValuesRef.current = values;
    getFreightEstimate({
      equipment: values.equipment,
      pickupDate: values.pickupDate,
      originAddressBreakdown: values.originAddressBreakdown,
      destinationAddressBreakdown: values.destinationAddressBreakdown,
      originAddress: {
        address1: values.originAddress_address1,
        city: values.originAddress_city,
        postalCode: values.originAddress_postalCode,
        region: values.originAddress_region,
        country: values.originAddress_country,
      },
      destinationAddress: {
        address1: values.destinationAddress_address1,
        city: values.destinationAddress_city,
        postalCode: values.destinationAddress_postalCode,
        region: values.destinationAddress_region,
        country: values.destinationAddress_country,
      },
    });
  };

  return (
    <Loader hasLoaded={!isLoading}>
      <NoQuoteModal
        open={noQuoteModalOpen}
        handleClose={() => setNoQuoteModalOpen(false)}
        submittedValues={submittedValuesRef.current}
      />
      <div className={classes.root}>
        <Body size="large">
          Email us at{' '}
          <a className={classes.link} href="mailto:shippersupport@reibus.com">
            shippersupport@reibus.com
          </a>{' '}
          or give us a call at{' '}
          <a className={classes.link} href="tel:+18888580648">
            (888) 858-0648
          </a>
          , if you need assistance or have any questions.
        </Body>

        <FinalForm initialValues={initialFormData} onSubmit={handleSubmit}>
          {({ handleSubmit }) => (
            <FormSpy
              subscription={{
                values: true,
                hasValidationErrors: true,
                errors: true,
              }}
            >
              {({ hasValidationErrors }) => {
                return (
                  <form
                    onSubmit={handleSubmit}
                    noValidate
                    className={classes.form}
                  >
                    <Location placesServiceInstance={placesServiceInstance} />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                      }}
                    >
                      <Box width={'400px'}>
                        <FormSection title="Equipment">
                          <EquipmentSingleSelect quickForm={true} />
                        </FormSection>
                      </Box>
                      <Box width={'280px'}>
                        <FormSection title="Ship Date">
                          <div>
                            <PickUpDateInput />
                            <Box padding="0.5rem 1rem">
                              <Body size="small">
                                *If no date is provided, we’ll assume a pickup
                                date 2 days from today
                              </Body>
                            </Box>
                          </div>
                        </FormSection>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                      }}
                    >
                      <Button
                        variant="contained"
                        type="submit"
                        className={classes.button}
                        disabled={
                          hasValidationErrors || isLoading || isSubmitting
                        }
                      >
                        Submit
                      </Button>
                    </Box>
                  </form>
                );
              }}
            </FormSpy>
          )}
        </FinalForm>
      </div>
    </Loader>
  );
};

export default QuickFreightQuoteForm;
