import axios from 'axios';
import { useQuery } from 'react-query';
import { getApiRoot } from 'utils/apiUtils';
import { useAuthorizationToken } from '@reibus/frontend-utility';
import { useFlags } from 'launchdarkly-react-client-sdk';

export type GetMarketConditionsParams = {
  city?: string;
  state: string;
  zipCode?: string;
  equipmentType: number;
  locationFit: LaneRateLocationFit;
};

export type LaneRateLocationFit =
  | '3_DIGIT_ZIP'
  | 'MARKET_AREA'
  | 'EXTENDED_MARKET_AREA'
  | 'STATE'
  | 'REGION'
  | '100_MILE_RADIUS';

export type MarketConditionsResponse = {
  loadToTruckRatio?: number;
  mciScore?: number;
};

const getMarketConditions = async ({
  params,
  authorization,
}: {
  params: GetMarketConditionsParams;
  authorization: string;
}): Promise<MarketConditionsResponse> => {
  const url = `${getApiRoot()}/freight-quotes/quote-manager/market-conditions`;

  const config = {
    headers: {
      Authorization: authorization,
    },
    params,
  };

  const { data } = await axios.get<MarketConditionsResponse>(url, config);

  return data;
};

const useGetMarketConditions = ({
  params,
  enabled,
}: {
  params: GetMarketConditionsParams | null;
  enabled: boolean;
}) => {
  const { ct1004LoadToTruckRatio } = useFlags();
  const authorization = useAuthorizationToken();
  const areParamsValid =
    !!params &&
    !!params.state &&
    !!params.equipmentType &&
    !!params.locationFit;
  const queryKey = [
    '/freight-quotes/quote-manager/market-conditions',
    params?.city,
    params?.state,
    params?.equipmentType,
    params?.locationFit,
    params?.zipCode,
  ];
  return useQuery<MarketConditionsResponse>(
    queryKey,
    () => (!params ? {} : getMarketConditions({ params, authorization })),
    {
      enabled: areParamsValid && enabled && ct1004LoadToTruckRatio,
    }
  );
};

export default useGetMarketConditions;
