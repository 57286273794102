import { Theme, Container, Box, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Location from './Location';
import Equipment from './Equipment';
import AdditionalInformation from './AdditionalInformation';
import { FreightQuoteRequest } from 'api/request-for-quote';
import { DotDivider } from 'components/icons/DotDivider';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: '#fff',
    margin: 0,
    padding: theme.spacing(3),
    borderRadius: '8px',
  },
  divider: {
    textAlign: 'center',
    margin: `${theme.spacing(2)} 0`,
  },
  dividerIcon: {},
  [theme.breakpoints.down('sm')]: {
    divider: {
      padding: `0 ${theme.spacing(3)}`,
    },
    container: {
      padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    },
  },
  tabletWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

type props = {
  quoteData: FreightQuoteRequest;
};

const QuoteInformation = ({ quoteData }: props) => {
  const classes = useStyles();
  const tablet = useMediaQuery('(min-width:500px) and (max-width:900px)');
  const tabletWrapperClass = tablet ? classes.tabletWrapper : '';
  return (
    <Container className={classes.container}>
      <Box
        sx={{
          height: '90%',
          width: '100%',
        }}
      >
        <div className={tabletWrapperClass}>
          <Location
            originAddress={quoteData.originAddress}
            destinationAddress={quoteData.destinationAddress}
          />
          {!tablet ? (
            <div className={classes.divider}>
              <DotDivider viewBox="0 0 300 2" />
            </div>
          ) : null}
          <Equipment equipment={quoteData.equipment} />
        </div>
        <div className={classes.divider}>
          <DotDivider viewBox="0 0 300 2" />
        </div>
        <AdditionalInformation
          pickupDate={quoteData.pickupDate}
          deliveryDate={quoteData.deliveryDate}
          weight={quoteData.weight}
        />
      </Box>
    </Container>
  );
};

export default QuoteInformation;
