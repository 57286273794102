import axios from 'axios';
import { useMutation } from 'react-query';
import { getApiRoot } from 'utils/apiUtils';
import { useAuthorizationToken } from '@reibus/frontend-utility';

type Location = {
  city: string;
  state: string;
  zipCode: string;
};

export type RatesRequest = {
  origin: Location;
  destination: Location;
  equipmentType: number;
};

export type RateSource = 'DAT' | 'REIBUS' | 'GREENSCREENS';

export type LaneRateLocationFit =
  | '3_DIGIT_ZIP'
  | 'MARKET_AREA'
  | 'EXTENDED_MARKET_AREA'
  | 'STATE'
  | 'REGION'
  | '100_MILE_RADIUS';

type HistoricalRateDatapoint = {
  date: string;
  rate: number;
  lowRate: number;
  highRate: number;
};

export type RateType = 'SPOT' | 'CONTRACT';

export type HistoricalRate = {
  monthlyRates: HistoricalRateDatapoint[];
  source: RateSource;
  rateType?: RateType;
  origin: {
    locationFit: LaneRateLocationFit;
  };
  destination: {
    locationFit: LaneRateLocationFit;
  };
};

export type HistoricalRatesResponse = {
  historicalRates: HistoricalRate[];
};

const getHistoricalRates = async ({
  payload,
  authorization,
}: {
  payload: RatesRequest;
  authorization: string;
}): Promise<HistoricalRatesResponse> => {
  const url = `${getApiRoot()}/freight-quotes/quote-manager/historical-rates`;
  const body = JSON.stringify(payload);
  const config = {
    headers: {
      Authorization: authorization,
    },
  };

  const { data } = await axios.post<HistoricalRatesResponse>(url, body, config);

  return data;
};

const useGetHistoricalRates = () => {
  const authorization = useAuthorizationToken();
  return useMutation<HistoricalRatesResponse, Error, RatesRequest>((payload) =>
    getHistoricalRates({ payload, authorization })
  );
};

export default useGetHistoricalRates;
