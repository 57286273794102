import { displayFormattingValue } from '@reibus/reibus-core-utils';

const displayFormattingOptions = {
  trailingZeros: false,
  decimalPlaces: 0,
};

export const formatRate = (rate?: number) => {
  return !rate
    ? 'N/A'
    : displayFormattingValue(rate, displayFormattingOptions, 'USD');
};
