import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import BlankNavBar from 'components/SteelUI/organisms/NavBar/BlankNavBar';
import Confirmation from 'features/cse/AutoAward/Confirmation';
import NotAvailable from 'features/cse/AutoAward/NotAvailable';
import PageTitle from 'components/PageTitle';
import useQueryAutoAward from 'features/cse/AutoAward/hooks/useQueryAutoAward';
import PageNotFound from 'pages/PageNotFound';
import PageBody from 'components/PageBody';
import Loader from 'components/Loader';

const AutoAwardConfirmationPage = () => {
  const { autoAwardId } = useParams<{ autoAwardId: string }>();
  const { data, isLoading } = useQueryAutoAward(autoAwardId);

  const awardStatus = data?.digitalFreightAward?.status;
  const isShipmentAvailable =
    data?.shipment?.label === 'Tendered' && awardStatus === 'OPEN';

  const getDescription = (shipmentId: string) => {
    return <Box>Shipment ID: {shipmentId}</Box>;
  };

  if (!isLoading && !data) {
    return <PageNotFound />;
  }

  return (
    <Box sx={{ backgroundColor: '#F6F7F7', minHeight: '100vh' }}>
      <BlankNavBar />
      <Loader hasLoaded={!isLoading}>
        {!data ? (
          <PageNotFound />
        ) : (
          <>
            <PageTitle
              title={
                isShipmentAvailable
                  ? 'Shipment Confirmation'
                  : 'Shipment Not Available'
              }
              description={getDescription(data.shipment.id)}
            />
            <PageBody>
              {isShipmentAvailable ? (
                <Confirmation id={autoAwardId} />
              ) : (
                <NotAvailable id={autoAwardId} />
              )}
            </PageBody>
          </>
        )}
      </Loader>
    </Box>
  );
};

export default AutoAwardConfirmationPage;
