import FormSection from './FormSection';
import { useField, useFormState } from 'react-final-form';
import { CheckboxWithLabel } from 'components/SteelUI/atoms/Checkbox';
import { Box } from '@mui/material';

const LABEL_MAP: Record<string, string> = {
  dunnage: 'Dunnage',
  chains: 'Chains',
  straps: 'Straps',
  loadLocks: 'Load Locks',
  excessiveCargoValue: 'Excessive Cargo Value (>+$100,000)',
  coilRacks: 'Coil Racks',
  edgeProtectors: 'Edge Protectors',
  eTracks: 'E-Tracks',
  loadBars: 'Load Bars',
  pipeStakes: 'Pipe Stakes',
  portPickup: 'Port Pickup',
};

type Layout = {
  columns: number;
  fields: string[];
};

const DEFAULT_LAYOUT_NEW = {
  columns: 4,
  fields: ['excessiveCargoValue'],
};

const LAYOUT_MAP_NEW: Record<string, Layout> = {
  FLATBED: {
    columns: 3,
    fields: ['excessiveCargoValue', 'portPickup'],
  },
  HOT_SHOT: {
    columns: 3,
    fields: ['excessiveCargoValue', 'portPickup'],
  },
  DRY_VAN: {
    columns: 3,
    fields: [
      'straps',
      'excessiveCargoValue',
      'eTracks',
      'loadLocks',
      'loadBars',
      'portPickup',
    ],
  },
};

const Accesorial = ({ field }: { field: string }) => {
  const { input } = useField(field);
  return (
    <Box key={field} paddingRight="1.5rem">
      <CheckboxWithLabel
        name={field}
        label={LABEL_MAP[field]}
        onChange={input.onChange}
      />
    </Box>
  );
};

const Accessorials = () => {
  const { values: formData } = useFormState();

  const equipment = formData.equipment || [];
  const layout = LAYOUT_MAP_NEW[equipment] || DEFAULT_LAYOUT_NEW;

  return (
    <FormSection title="Accessorials">
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {layout.fields.map((field) => (
          <Accesorial key={field} field={field} />
        ))}
      </Box>
    </FormSection>
  );
};

export default Accessorials;
