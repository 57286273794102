import PageWrapper from 'components/PageWrapper';
import { makeStyles } from '@mui/styles';
import { usePageView } from 'utils/mixpanel';
import { H3, Title } from 'components/SteelUI/atoms/typography';
import RequestForQuoteForm from './form';

import { useState, useEffect } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import { FreightQuoteRequest } from 'api/request-for-quote';
import { useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useGetResourcePreFrieghtData from './hooks/useGetResourcePreFreightData';

import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

type AutoCompletionRequest = google.maps.places.AutocompletionRequest;

const useStyles = makeStyles({
  root: {
    backgroundColor: '#F6F7F7',
    minHeight: '100vh',
  },
  header: {
    backgroundColor: '#FFFFFF',
    padding: '24px 32px',
  },
  formWrapper: {
    padding: '32px 24px',
  },
});

const DEFAULT_FORM_DATA: Record<string, any> = {
  originSystem: 'FREIGHT_QUOTE_UI',
  equipment: ['FLATBED'],
};

const getPlacePredictionsFns = (types: string[]) =>
  useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    options: {
      types,
    } as AutoCompletionRequest,
  });

export type PlacePredictionFn = (
  opt: google.maps.places.AutocompletionRequest
) => void;

type Prefix = 'originAddress' | 'destinationAddress';
type Suffix = 'address1' | 'city' | 'region' | 'postalCode' | 'country';
type AddressField = `${Prefix}_${Suffix}`;
type ManualAddress = Record<AddressField, string | undefined>;

const generateManualAddressFromState = (
  state: FreightQuoteRequest
): FreightQuoteRequest & ManualAddress => {
  if (!state) {
    return {} as FreightQuoteRequest & ManualAddress;
  }

  const {
    destinationAddress: {
      address1: destinationAddress_address1,
      city: destinationAddress_city,
      region: destinationAddress_region,
      postalCode: destinationAddress_postalCode,
      country: destinationAddress_country,
    } = {},
    originAddress: {
      address1: originAddress_address1,
      city: originAddress_city,
      region: originAddress_region,
      postalCode: originAddress_postalCode,
      country: originAddress_country,
    } = {},
  } = state;

  return {
    ...state,
    destinationAddress_address1,
    destinationAddress_city,
    destinationAddress_region,
    destinationAddress_postalCode,
    destinationAddress_country,
    originAddress_address1,
    originAddress_city,
    originAddress_region,
    originAddress_postalCode,
    originAddress_country,
  };
};

const RequestForQuote = () => {
  const classes = useStyles();
  usePageView(`Page View: Request for freight quote`, {
    currentTab: 'request-for-quote',
  });
  const { data: currentUser, isLoading: isUserLoading } = useCurrentUser();
  const { state, search } = useLocation<FreightQuoteRequest>();

  const { ct238PrefillFqrFormFromOffer } = useFlags();
  const [initialFormData, setInitialFormData] = useState<Record<string, any>>(
    {}
  );
  const [isLoading, setIsLoading] = useState(true);
  const [contactInfo, setContactInfo] = useState<Record<string, any>>({});

  const searchParams = new URLSearchParams(search);
  const resourceId = searchParams.get('resourceId');

  const { data: resourceData, isLoading: isResourceDataLoading } =
    useGetResourcePreFrieghtData(resourceId);

  let placesServiceInstance: google.maps.places.PlacesService | null = null;

  ['(regions)', 'address'].forEach((searchType) => {
    const { placesService = null } = getPlacePredictionsFns([searchType]);
    if (!placesServiceInstance) {
      placesServiceInstance = placesService;
    }
  });

  useEffect(() => {
    if (!isUserLoading && contactInfo.contactName) {
      const baseFormState = {
        ...initialFormData,
        ...DEFAULT_FORM_DATA,
        ...contactInfo,
      };

      if (state && placesServiceInstance) {
        setInitialFormData({
          ...baseFormState,
          ...generateManualAddressFromState(state),
        });
        setIsLoading(false);
      }
      if (
        resourceId &&
        resourceData &&
        ct238PrefillFqrFormFromOffer &&
        placesServiceInstance
      ) {
        setInitialFormData({
          ...baseFormState,
          ...resourceData,
        });
        setIsLoading(false);
      } else if (
        !state &&
        (!resourceId ||
          (resourceId &&
            (!ct238PrefillFqrFormFromOffer ||
              (!resourceData && !isResourceDataLoading))))
      ) {
        setInitialFormData(baseFormState);
        setIsLoading(false);
      }
    }
  }, [resourceData, contactInfo.contactName, placesServiceInstance]);

  useEffect(() => {
    if (!isUserLoading && currentUser) {
      setContactInfo({
        contactName: `${currentUser.firstName} ${currentUser.lastName}`,
        contactEmail: currentUser.email,
        contactPhone: currentUser.phoneNumber,
      });
    } else if (!isUserLoading && !contactInfo.contactName) {
      setInitialFormData(DEFAULT_FORM_DATA);
      setIsLoading(false);
    }
  }, [isUserLoading]);

  return (
    <PageWrapper showFooter={false}>
      <div className={classes.root}>
        <div className={classes.header}>
          <Title weight="bold">
            {state?.bookShipment ? 'Book Freight' : 'Get Quote & Book Freight'}
          </Title>
          <H3 textTransform="capitalize">
            {state?.bookShipment
              ? 'Enter shipment details to book freight'
              : 'Enter your shipment details to get an instant quote'}
          </H3>
        </div>
        <div className={classes.formWrapper}>
          {!isLoading && initialFormData.originSystem ? (
            <RequestForQuoteForm
              isLoading={isLoading}
              placesServiceInstance={placesServiceInstance}
              initialFormData={initialFormData}
            />
          ) : null}
        </div>
      </div>
    </PageWrapper>
  );
};

export default RequestForQuote;
