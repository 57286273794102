import React, { useState } from 'react';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { FONT_WEIGHT_BOLD } from 'theme';

interface CustomButtonProps extends LoadingButtonProps {
  toggle?: boolean;
}

interface StyledButtonProps {
  isToggled: boolean;
  variant: 'contained' | 'outlined' | 'text'; // include 'text' if needed
}

const StyledLoadingButton = styled(LoadingButton, {
  shouldForwardProp: (prop) => prop !== 'isToggled',
})<StyledButtonProps>(({ theme, isToggled, variant }) => ({
  textTransform: 'none',
  fontWeight: FONT_WEIGHT_BOLD,
  borderRadius: 8,
  minWidth: '7.5rem',
  maxHeight: '2.625rem',
  height: '2.625rem',

  ...(variant === 'contained'
    ? {
        backgroundColor: theme.palette.primary.main,
        color: '#ffffff',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#215199',
          boxShadow:
            '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
        '&:focus': {
          borderRadius: '0.5rem',
          boxShadow: 'none',
          outline: '3px solid rgba(33, 81, 153, 0.20)',
          backgroundColor: theme.palette.primary.main,
        },
        '&:active': {
          backgroundColor: '#215199',
          boxShadow: 'none',
          outline: 'none',
        },
      }
    : {}),

  ...(variant === 'outlined'
    ? {
        backgroundColor: isToggled
          ? theme.palette.secondary.main
          : 'transparent',
        color: theme.palette.primary.main,
        border: isToggled
          ? `2px solid ${theme.palette.secondary.main}`
          : '2px solid #2D6CCB',
        '&:hover': {
          backgroundColor: theme.palette.secondary.main,
          boxShadow:
            '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
          border: `2px solid ${theme.palette.secondary.main}`,
        },
        '&:focus': {
          borderRadius: '0.5rem',
          outline: '3px solid rgba(33, 81, 153, 0.20)',
          background: isToggled
            ? theme.palette.secondary.main
            : theme.palette.common.white,
          border: isToggled
            ? `2px solid ${theme.palette.primary.main}`
            : '2px solid #2D6CCB',
        },
        '&:active': {
          backgroundColor: theme.palette.secondary.main,
          border: `2px solid ${theme.palette.secondary.main}`,
          outline: 'none',
          boxShadow: 'none',
        },
        '&.Mui-disabled': {
          border: '2px solid #EDEEF0',
        },
      }
    : {}),

  '&.Mui-disabled': {
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[300]}`,
  },
}));

const Button = ({
  variant = 'contained',
  toggle = false,
  children,
  onClick,
  ...rest
}: CustomButtonProps) => {
  const [isToggled, setIsToggled] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (toggle) {
      setIsToggled((prev) => !prev);
    }
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <StyledLoadingButton
      variant={variant}
      isToggled={isToggled}
      onClick={handleClick}
      disableRipple
      disableFocusRipple
      disableElevation
      {...rest}
    >
      <span>{children}</span>
    </StyledLoadingButton>
  );
};

export default Button;
