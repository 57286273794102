import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const calendarIcon = icon({ name: 'calendar', style: 'light' });

type Props = {
  label: string;
  value: Date | null;
  onChange: (date: Date | null) => void;
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
};

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      fontSize: '16px',
      borderRadius: '0.5rem',
      height: '2.625rem',
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '0.5rem',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#2D6CCB',
        borderWidth: '2px',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#2D6CCB',
      },
    },
  },
  label: {
    fontSize: '16px',
    display: 'flex',
    flexDirection: 'row',
    '&.MuiInputLabel-root:hover': {
      color: '#2D6CCB',
    },
    '&.Mui-focused': {
      color: '#2D6CCB',
    },
    '&.MuiFormLabel-filled': {
      color: '#2D6CCB',
    },
    '& .MuiFormLabel-asterisk': {
      order: -1,
      margin: '0 4px 0 0',
    },
  },
  calendarIcon: {
    color: '#64676B',
    fontSize: '14px',
    margin: '0 8px',
    display: 'flex',
    alignItems: 'center',
  },
  paper: {
    '& .MuiPaper-root': {
      borderRadius: '0.5rem',
      boxShadow: 'none',
      filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))',
    },
    '& .MuiPickersCalendarHeader-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '8px 0',
      marginTop: '8px',
      position: 'relative',
    },
    '& .MuiPickersArrowSwitcher-root': {
      position: 'absolute',
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'space-between',
      '& .MuiIconButton-root:first-of-type': {
        marginLeft: '12px',
      },
      '& .MuiIconButton-root:last-of-type': {
        marginRight: '12px',
      },
    },
    '& .MuiPickersCalendarHeader-labelContainer': {
      margin: '0',
      position: 'relative',
      zIndex: 1,
    },
    '& .MuiPickersDay-root': {
      '&:not(.Mui-selected):hover, &:not(.Mui-selected):focus': {
        backgroundColor: '#F5F5F5',
        border: '1px solid rgba(0, 0, 0, 0.6)',
      },
      '&.Mui-selected': {
        backgroundColor: '#2D6CCB',
        color: '#fff',
      },
    },
  },
}));

const DatePickerField = ({
  label,
  value,
  onChange,
  required,
  minDate,
  maxDate,
}: Props) => {
  const { classes, cx } = useStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        views={['day']}
        inputFormat="MM/dd/yyyy"
        components={{
          OpenPickerIcon: () => (
            <FontAwesomeIcon
              icon={calendarIcon}
              className={cx(classes.calendarIcon)}
            />
          ),
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            size="small"
            fullWidth
            className={cx(classes.root)}
            InputLabelProps={{
              ...params.InputLabelProps,
              className: classes.label,
            }}
          />
        )}
        PopperProps={{
          className: classes.paper,
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePickerField;
