import * as flagSvgs from './svgs';

const FlagFromImg = (flag: string, width = 26, height = 17) => {
  if (!flag) {
    return null;
  }

  return <img alt="logo" src={flag} width={width} height={height} />;
};

const Flag = ({ country }: { country: 'USA' | 'MEX' | 'CAN' }) =>
  FlagFromImg(flagSvgs[country] ?? '');

export default Flag;
