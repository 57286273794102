import { makeStyles } from '@mui/styles';
import FormSection from './FormSection';
import { useField, useFormState } from 'react-final-form';
import Dropdown from 'components/SteelUI/molecules/dropdowns/Dropdown';
import TextField from './TextField';
import { NumberFormatCustom } from './masks';

const useStyles = makeStyles({
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    columnGap: '24px',
    rowGap: '40px',
  },
});

const COMMODITY_OPTIONS = [
  {
    label: 'Coil',
    value: 'COIL',
  },
  {
    label: 'Skidded Coils',
    value: 'SKIDDED_COILS',
  },
  {
    label: 'Sheet',
    value: 'SHEET',
  },
  {
    label: 'Plate',
    value: 'PLATE',
  },
  {
    label: 'Pipe',
    value: 'PIPE',
  },
  {
    label: 'Long Product',
    value: 'LONG_PRODUCT',
  },
];

const NumberInputProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputComponent: NumberFormatCustom as any,
};

const Load = () => {
  const classes = useStyles();
  const { values: formData } = useFormState();
  const { input } = useField('commodityType');

  return (
    <FormSection title="Load">
      <div className={classes.content}>
        <Dropdown
          label="Commodity Type"
          onChange={input.onChange}
          value={input.value}
          options={COMMODITY_OPTIONS}
          required
        />
        {formData.commodityType === 'COIL' ? (
          <>
            <TextField
              name="numberOfCoils"
              label="Number of Coils"
              InputProps={NumberInputProps}
              required
            />
            <TextField
              name="apxWeightPerCoil"
              label="Approx Weight per Coil (Lbs)"
              required
              InputProps={NumberInputProps}
            />
            <TextField
              name="weight"
              label="Total Quote Weight (Lbs)"
              InputProps={NumberInputProps}
              disabled
              required={false}
              value={
                formData.numberOfCoils && formData.apxWeightPerCoil
                  ? formData.numberOfCoils * formData.apxWeightPerCoil
                  : undefined
              }
            />
          </>
        ) : null}
        {formData.commodityType === 'SKIDDED_COILS' ? (
          <>
            <TextField
              name="quantity"
              label="Number of Skids"
              InputProps={NumberInputProps}
            />
            <TextField
              name="weight"
              label="Weight (Lbs)"
              required
              InputProps={NumberInputProps}
            />
          </>
        ) : null}
        {formData.commodityType === 'PLATE' ||
        formData.commodityType === 'SHEET' ? (
          <>
            <TextField
              name="length"
              label="Length (in)"
              required
              InputProps={NumberInputProps}
            />
            <TextField
              name="width"
              label="Width (in)"
              required
              InputProps={NumberInputProps}
            />
            <TextField
              name="weight"
              label="Weight (Lbs)"
              required
              InputProps={NumberInputProps}
            />
            <TextField
              name="apxSkidCount"
              label="Approx Skid Count"
              InputProps={NumberInputProps}
            />
          </>
        ) : null}
        {formData.commodityType === 'PIPE' ? (
          <>
            <TextField
              name="outsideDiameter"
              label="Outside Diameter (in)"
              required
              InputProps={NumberInputProps}
            />
            <TextField
              name="length"
              label="Length (ft)"
              required
              InputProps={NumberInputProps}
            />
            <TextField
              name="height"
              label="Height (in)"
              InputProps={NumberInputProps}
            />
            <TextField
              name="weight"
              label="Weight (Lbs)"
              required
              InputProps={NumberInputProps}
            />
            <TextField
              name="apxPieces"
              label="Approx Pieces"
              required
              InputProps={NumberInputProps}
            />
          </>
        ) : null}
        {formData.commodityType === 'LONG_PRODUCT' ? (
          <>
            <TextField
              name="length"
              label="Length (in)"
              required
              InputProps={NumberInputProps}
            />
            <TextField
              name="width"
              label="Width (in)"
              InputProps={NumberInputProps}
            />
            <TextField
              name="height"
              label="Height (in)"
              InputProps={NumberInputProps}
            />
            <TextField
              name="weight"
              label="Weight (Lbs)"
              required
              InputProps={NumberInputProps}
            />
            <TextField
              name="apxPieces"
              label="Approx Pieces"
              InputProps={NumberInputProps}
            />
          </>
        ) : null}
      </div>
    </FormSection>
  );
};

export default Load;
