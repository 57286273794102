import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { isBefore, format, isSameDay, isValid } from 'date-fns';

export const stringValidator = (value?: string): string | undefined =>
  value ? undefined : 'Required';

export const numberAllowZeroValidator = (
  value?: string
): string | void | null => {
  value ? null : 'Required';
};

export const autoSelectValidator = (value?: string[]): string | undefined =>
  value && value.length > 0 ? undefined : 'Required';

export const phoneValidator = (value?: string): string | undefined => {
  if (!value) {
    return 'Please enter a phone number.';
  }

  const phoneNumber = value.startsWith('+1') ? value : `+1${value}`;

  if (!isPossiblePhoneNumber(phoneNumber)) {
    return 'Please enter a valid phone number.';
  }
};

export const booleanValidator = (value?: boolean): string | undefined =>
  value !== undefined ? undefined : 'Required';

export const minDateCurrentDateValidator = (
  value?: string
): string | undefined => {
  if (value) {
    const enteredDate = new Date(value);
    const currentDate = new Date();
    const compDate1 = isSameDay(enteredDate, currentDate)
      ? currentDate
      : enteredDate;
    return isBefore(compDate1, currentDate)
      ? `Date should not be before ${format(currentDate, 'MM/dd/yyyy')}`
      : undefined;
  }

  return undefined;
};

export const dateValueValidator = (value?: string): string | undefined =>
  value ? undefined : 'Required';

export const validDateValidator = (value?: string): string | undefined =>
  value && !isValid(new Date(value)) ? 'Please enter a valid date' : undefined;

export const anotherInputMinDateValidator = (
  otherInputValue: string,
  errorMessage: string,
  value?: string
): string | undefined => {
  if (value) {
    const enteredDate = new Date(value);
    const currentDate = new Date();
    const compDate1 = isSameDay(enteredDate, currentDate)
      ? currentDate
      : enteredDate;
    const compDate2 = new Date(otherInputValue).setHours(0, 0, 0, 0);

    return isValid(compDate2) && isBefore(compDate1, compDate2)
      ? errorMessage
      : undefined;
  }
  return undefined;
};

export const dateValidatorFactory =
  (
    required: boolean,
    disablePast: boolean,
    errorMessage: string,
    minDate?: string
  ) =>
  (value?: string) => {
    const requiredValidator = required ? dateValueValidator(value) : undefined;
    const validDate = validDateValidator(value);
    const currentDateValidator = disablePast
      ? minDateCurrentDateValidator(value)
      : undefined;
    const dateGreaterThanMin = minDate
      ? anotherInputMinDateValidator(minDate, errorMessage, value)
      : undefined;

    // based on the metadata for the input, we will do every appropriate validation
    // if any of the functions above returns an error message, it will display based on priority set below (start of the array is highest prio)
    return [
      requiredValidator,
      validDate,
      currentDateValidator,
      dateGreaterThanMin,
    ].find((el) => el);
  };

// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateEmail(email?: string) {
  return email ? EMAIL_REGEX.test(email.toLowerCase()) : true;
}

export const emailValidator = (value?: string): string | undefined =>
  validateEmail(value) ? undefined : 'Please enter a valid email address.';

export const allowOnlyLettersAndSpaces = (value: string) => {
  const regex = /^[a-zA-Z ]*$/;
  return regex.test(value) ? undefined : 'Only letters and spaces are allowed';
};
