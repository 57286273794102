import { useField } from 'react-final-form';
import { Country } from 'components/library/form/AddressFieldTypes';
import {
  CanadaRegions,
  MexicanRegions,
  UniteStatesRegions,
} from 'components/library/form/RegionSelectOptions';
import Dropdown from 'components/SteelUI/molecules/dropdowns/Dropdown';

import type { SelectOption } from 'components/library/form/types';

const getOptionsBasedOnCountry = (country: Country): SelectOption[] => {
  switch (country) {
    case 'MEX':
      return MexicanRegions;
    case 'CAN':
      return CanadaRegions;
    case 'USA':
      return UniteStatesRegions;
  }
};

export const RegionSelect = ({ name }: { name: string }) => {
  const { input, meta } = useField(`${name}_region`, {
    validate: (value, allValues: Record<string, any>) =>
      !value && !allValues[`${name}Breakdown`] ? 'Required' : undefined,
  });

  const { input: countryInput } = useField(`${name}_country`, {
    subscription: { value: true },
  });

  const options = getOptionsBasedOnCountry(
    (countryInput.value ?? 'USA') as Country
  );

  return (
    <Dropdown
      onChange={input.onChange}
      value={input.value}
      label="State"
      required
      options={
        options?.map(({ id, label }) => ({
          label,
          value: id,
        })) || []
      }
      error={meta.error && meta.touched ? (meta.error as string) : ''}
      id={input.name}
      searchable
    />
  );
};
