import FormSection from './FormSection';
import { AddressField } from './AddressFieldWithPrefill';

import { Stack } from '@mui/material';

type LocationProps = {
  placesServiceInstance: google.maps.places.PlacesService | null;
};

const Location = ({ placesServiceInstance }: LocationProps) => {
  return (
    <FormSection title="Location">
      <Stack direction="row" spacing={3}>
        <AddressField
          name="originAddress"
          label="Origin"
          required
          placesServiceInstance={placesServiceInstance}
        />
        <AddressField
          name="destinationAddress"
          label="Destination"
          required
          placesServiceInstance={placesServiceInstance}
        />
      </Stack>
    </FormSection>
  );
};

export default Location;
