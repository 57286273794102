import { makeStyles } from '@mui/styles';
import FormSection from './FormSection';
import Dropdown from 'components/SteelUI/molecules/dropdowns/Dropdown';
import { useField } from 'react-final-form';

const useStyles = makeStyles({
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: '24px',
    rowGap: '40px',
  },
});

const EQUIPMENT_OPTIONS = [
  {
    id: '',
    label: 'Flatbed',
    optionName: 'FLATBED',
  },
  {
    id: '',
    label: 'Dry Van',
    optionName: 'DRY_VAN',
  },
  {
    id: '',
    label: 'Hot Shot',
    optionName: 'HOT_SHOT',
  },
];

export const EquipmentSingleSelect = ({
  quickForm = false,
}: {
  quickForm?: boolean;
}) => {
  const { input } = useField('equipment', {
    validate: (value) => (value ? undefined : 'Required'),
  });
  const equipmentOptions = quickForm
    ? EQUIPMENT_OPTIONS.filter((option) => option.optionName !== 'HOT_SHOT')
    : EQUIPMENT_OPTIONS;

  return (
    <Dropdown
      onChange={input.onChange}
      label="Equipment Type"
      options={equipmentOptions.map(({ label, optionName }) => ({
        label,
        value: optionName,
      }))}
      required
      value={input.value}
    />
  );
};

const Equipment = () => {
  const classes = useStyles();

  return (
    <FormSection title="Equipment">
      <div className={classes.content}>{<EquipmentSingleSelect />}</div>
    </FormSection>
  );
};

export default Equipment;
