import { useState, memo, useEffect } from 'react';
import {
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
  Marker,
  Circle,
} from '@react-google-maps/api';
import { StopInfo, Coordinate } from 'api/types';
import { useGoogleMapsScript } from 'hooks/useGoogleMapsScript';
import { GoogleMapsScriptProvider } from 'hooks/useGoogleMapsScript';
import { Box } from '@mui/material';
import theme from 'theme';

const fiftyMilesToMeters = 80467.2;

const containerStyle = {
  width: '100%',
  height: '100%',
};

export const createDirectionRequest = (stops: StopInfo[]) => {
  const index = stops.length - 1;
  const waypoints = stops
    .filter((_, i) => i > 0 && i < index)
    .map((value) => {
      return {
        location: new google.maps.LatLng(
          value.coordinate.lat,
          value.coordinate.lon
        ),
        stopover: true,
      };
    });

  const request = {
    origin: new google.maps.LatLng(
      stops[0].coordinate.lat,
      stops[0].coordinate.lon
    ),
    destination: new google.maps.LatLng(
      stops[index].coordinate.lat,
      stops[index].coordinate.lon
    ),
    travelMode: google.maps.TravelMode.DRIVING,
    waypoints: waypoints,
  };

  return request;
};

type LocationBubbleProps = {
  lat: number;
  lng: number;
  radius: number;
};

export const LocationBubble = ({ lat, lng, radius }: LocationBubbleProps) => (
  <Circle
    radius={radius}
    center={{
      lat,
      lng,
    }}
    options={{
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
    }}
  />
);

const getDirectionServiceCallback = (
  setDirections: (result: google.maps.DirectionsResult | null) => void,
  hasLoadedDirectionsServiceCallback: boolean,
  setHasLoadedDirectionsServiceCallback: (hasLoaded: boolean) => void
) => {
  const directionServiceCallback = (
    result: google.maps.DirectionsResult | null,
    status: google.maps.DirectionsStatus
  ) => {
    if (
      status === google.maps.DirectionsStatus.OK &&
      !hasLoadedDirectionsServiceCallback
    ) {
      setDirections(result);
      setHasLoadedDirectionsServiceCallback(true);
    }
    if (status !== google.maps.DirectionsStatus.OK) {
      console.error(result);
    }
  };
  return directionServiceCallback;
};

const MAP_ID = '3fc4139653797c82';

const defaultOptions = {
  disableDefaultUI: true,
  scaleControl: false,
  gestureHandling: 'none',
  keyboardShortcuts: false,
  mapId: MAP_ID,
};

const defaultMapTypeId = 'roadmap';

type RoadMapProps = {
  stops: StopInfo[];
  suppressInfoWindows?: boolean;
  showStartLocationBubble?: boolean;
  truckLocation?: Coordinate;
  options?: google.maps.MapOptions;
  mapTypeId?: string;
  width?: string;
  height?: string;
};

const RouteMap = ({
  stops,
  suppressInfoWindows = true,
  showStartLocationBubble = false,
  truckLocation,
  options = defaultOptions,
  mapTypeId = defaultMapTypeId,
  width,
  height,
}: RoadMapProps) => {
  const [directions, setDirections] =
    useState<google.maps.DirectionsResult | null>(
      {} as google.maps.DirectionsResult
    );
  const [
    hasLoadedDirectionsServiceCallback,
    setHasLoadedDirectionsServiceCallback,
  ] = useState(false);

  useEffect(() => {
    setHasLoadedDirectionsServiceCallback(false);
  }, [stops]);

  const { isLoaded: hasLoadedGoogleMapScript } = useGoogleMapsScript();

  return hasLoadedGoogleMapScript ? (
    <Box sx={{ width, height }}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={11}
        options={options}
        mapTypeId={mapTypeId}
      >
        <DirectionsService
          options={createDirectionRequest(stops)}
          callback={getDirectionServiceCallback(
            setDirections,
            hasLoadedDirectionsServiceCallback,
            setHasLoadedDirectionsServiceCallback
          )}
        />
        {directions && hasLoadedDirectionsServiceCallback ? (
          <DirectionsRenderer
            options={{
              suppressMarkers: true,
              directions: directions,
              suppressInfoWindows: suppressInfoWindows,
              polylineOptions: {
                strokeColor: theme.palette.primary.main,
                strokeWeight: 6,
              },
            }}
          />
        ) : null}
        {showStartLocationBubble ? (
          <LocationBubble
            lat={stops[0].coordinate.lat}
            lng={stops[0].coordinate.lon}
            radius={fiftyMilesToMeters}
          />
        ) : null}
        {stops.map((value, index) => (
          <Marker
            key={index}
            position={{
              lat: value.coordinate.lat,
              lng: value.coordinate.lon,
            }}
            label={{
              color: 'white',
              text: value.stopType.charAt(0).toUpperCase(),
            }}
            icon={{
              url: 'https://res.cloudinary.com/reibus/image/upload/v1674493272/Reibus%20Logistics/m9jlclk32wsn6gdjk8ej.svg',
              anchor: new google.maps.Point(18, 18),
              scaledSize: new google.maps.Size(25, 25),
            }}
            zIndex={100}
          />
        ))}
        {truckLocation ? (
          <Marker
            position={{
              lat: truckLocation.lat,
              lng: truckLocation.lon,
            }}
            icon={{
              url: 'https://res.cloudinary.com/reibus/image/upload/v1674493553/Reibus%20Logistics/aip1inqgzil92ogon70n.svg',
              anchor: new google.maps.Point(30, 30),
            }}
            zIndex={100}
          />
        ) : null}
      </GoogleMap>
    </Box>
  ) : null;
};

const RouteMapWithProvider = (props: RoadMapProps) => (
  <GoogleMapsScriptProvider>
    <RouteMap {...props} />
  </GoogleMapsScriptProvider>
);

export default memo(RouteMapWithProvider);
export type { RoadMapProps };
export const exportedForTesting = {
  getDirectionServiceCallback,
};
