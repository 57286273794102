import { Box, FormControlLabel, MenuItem, MenuItemProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { CheckboxWithLabel } from 'components/SteelUI/atoms/Checkbox';
import Radio from 'components/SteelUI/atoms/Radio';

type Props = {
  value: string;
  label: string;
  isSelected: boolean;
  icon?: JSX.Element | null;
  checkbox?: boolean;
  variant?: 'default' | 'checkbox' | 'radio';
  indented?: boolean;
} & MenuItemProps;

const useStyles = makeStyles()(({ palette }) => ({
  menuItem: {
    height: '2.625rem',
    minHeight: '2.625rem',
    color: '#64676B',
    '&.Mui-selected': {
      backgroundColor: '#FFF',
    },
    '&:focus': {
      backgroundColor: '#FFF !important',
      border: '3px solid #21519933',
    },
    '&:hover': {
      backgroundColor: `${palette.secondary.main} !important`,
    },
  },
  menuItemSelected: {
    height: '2.625rem',
    minHeight: '2.625rem',
    backgroundColor: `${palette.secondary.main} !important`,
    fontWeight: 700,
    color: '#212224',
  },
}));

const DropdownOption = ({
  value,
  label,
  icon,
  isSelected,
  variant = 'default',
  indented,
  ...rest
}: Props) => {
  const { classes } = useStyles();

  return (
    <MenuItem
      value={value}
      className={isSelected ? classes.menuItemSelected : classes.menuItem}
      sx={{ whiteSpace: 'normal' }}
      {...rest}
    >
      <Box
        sx={{
          marginLeft: indented ? '1rem' : undefined,
          display: icon ? 'flex' : 'block',
        }}
      >
        {icon ? icon : null}
        {variant === 'default' ? label : null}
        {variant === 'checkbox' ? (
          <CheckboxWithLabel
            checked={isSelected}
            label={label}
            typographyProps={isSelected ? { fontWeight: '700' } : undefined}
          />
        ) : null}
        {variant === 'radio' ? (
          <FormControlLabel
            value={value}
            control={<Radio checked={isSelected} />}
            label={label}
          />
        ) : null}
      </Box>
    </MenuItem>
  );
};

export default DropdownOption;
