import { Chip as MuiChips, ChipProps } from '@mui/material';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 50,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: 1.5,
    alignText: 'center',
    textTransform: 'none',
    letterSpacing: 0.5,
    height: 26,
    color: theme.palette.text.primary,
  },
  primary: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[300]}`,
    whiteSpace: 'nowrap',
  },
  secondary: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    whiteSpace: 'nowrap',
    backgroundColor: `rgba(45, 157, 120, 0.1)`,
    border: `1px solid ${theme.palette.success.main}`,
  },
  outlined: {
    fontSize: theme.typography.body2.fontSize,
    padding: `1px ${theme.spacing(1)}`,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  outlineSecondary: {
    backgroundColor: `#F0FCFE`,
    border: `1px solid #26C3FB`,
  },
  outlineSecondaryIcon: {
    color: '#5DD2FC',
  },
}));

const Chip = ({ ...rest }: ChipProps) => {
  const {
    root,
    primary,
    secondary,
    outlined,
    outlineSecondary,
    outlineSecondaryIcon,
  } = useStyles();
  return (
    <MuiChips
      classes={{
        root: root,
        colorPrimary: primary,
        colorSecondary: secondary,
        outlinedPrimary: outlined,
        outlinedSecondary: outlineSecondary,
        deleteIconOutlinedColorSecondary: outlineSecondaryIcon,
      }}
      {...rest}
    />
  );
};

export default Chip;
