export type Country = 'MEX' | 'CAN' | 'USA';

export type CountrySelectOption = {
  id: Country;
  optionName: Country;
  label: string;
};

export const CurrentCountries: CountrySelectOption[] = [
  {
    id: 'CAN',
    optionName: 'CAN',
    label: 'Canada',
  },
  {
    id: 'MEX',
    optionName: 'MEX',
    label: 'Mexico',
  },
  {
    id: 'USA',
    optionName: 'USA',
    label: 'United States',
  },
];

export const AddressFieldsTypes = [
  {
    name: 'address1',
    databaseName: 'address1',
    description: 'Address1',
    inputType: 'TEXT',
    label: 'Street Address',
    colWidth: 2,
    rowHeight: 1,
    required: true,
  },
  {
    name: 'country',
    databaseName: 'country',
    description: 'Country',
    inputType: 'SELECT',
    label: 'Country',
    colWidth: 1,
    options: CurrentCountries,
    required: true,
  },
  {
    name: 'city',
    databaseName: 'city',
    description: 'City',
    inputType: 'TEXT',
    label: 'City',
    colWidth: 1,
    required: true,
  },
  {
    name: 'region',
    databaseName: 'region',
    description: 'State',
    inputType: 'REGION_SELECT',
    label: 'State',
    colWidth: 1,
    required: true,
  },
  {
    name: 'postalCode',
    databaseName: 'postalCode',
    description: 'Zipcode',
    inputType: 'TEXT',
    label: 'Zip',
    colWidth: 1,
    required: true,
  },
];
