import { forwardRef } from 'react';
import { makeStyles } from '@mui/styles';
import NumberFormat from 'react-number-format';
import { TextField } from '@mui/material';
import { Field } from 'react-final-form';
import { FormFieldError } from './FormFieldError';
import { phoneValidator as defaultValidator } from './fieldValidators';

const useTextFieldStyles = makeStyles(() => ({
  asterisk: {
    color: '#c62828',
    fontSize: '1.2rem',
  },
}));
interface PhoneInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const PhoneInput = forwardRef<
  NumberFormat<PhoneInputProps>,
  PhoneInputProps
>(function PhoneInput(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="+# (###) ###-####"
      mask="_"
    />
  );
});

type PhoneFieldProps = {
  placeholder?: string;
  name: string;
  label: string;
  required?: boolean;
  validator?: (value: string) => string | void;
};

export const PhoneField = ({
  name,
  label,
  required = true,
  validator = defaultValidator,
}: PhoneFieldProps) => {
  const classes = useTextFieldStyles();
  const validate = required ? validator : undefined;
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        return (
          <div>
            <TextField
              label={label}
              onChange={input.onChange}
              onFocus={input.onFocus}
              onBlur={input.onBlur}
              value={input.value}
              name={name}
              id={name}
              required={required}
              InputProps={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputComponent: PhoneInput as any,
              }}
              InputLabelProps={{
                shrink: !!input.value,
                classes: {
                  asterisk: classes.asterisk,
                },
              }}
              fullWidth
              variant="outlined"
            />

            <FormFieldError meta={meta} />
          </div>
        );
      }}
    </Field>
  );
};
